//통합앱 구축팀 요청...통합앱 식별 스크립트
var isApp = function(){
    return /lgeapp/i.test(navigator.userAgent);
}

/* goAppUrl : 앱실행및 해당 경로로 랜딩하는 함수
*  @path : 랜딩할 경로
*/
var goAppUrl = function(path) {
    var weblink = decodeURIComponent(path ? path : location.href);    
    location.href =  'https://lgeapp.page.link/?link='+encodeURIComponent(weblink)+'&apn=kr.co.lge.android&isi=1561079401&ibi=kr.co.lge.ios'; // 앱실행 
}
//임시 성능/속도 개선 관련 설정 제외 경로 콜백
var exceptPath = {
    '/' : function(){
    },
    '/care-solutions/rentalService':function(){
    }
}
/*
속도개선 과정에서 sticky모듈 'libs/jquery.transit.min' 삭제 후 stg반영시 에러 발생
원인은 페이지별 스크립트(/care-solutions/subs-package > caresolutionHome.min.js)실행속도가 빨라져 window.breakpoint 변수가 담기기 전에 
페이지별 스크립트에서 해당변수를 참조해서 발생하는 에러. 아래코드로 에러대응
*/
var breakpointObj;
if (window.innerWidth < 768) {
    // mobile
    breakpointObj = {
        name: 'mobile',
        min: 0,
        max: 767,
        isMobile: true,
        isPc: false,
        prev: window.breakpoint || {}
    };
} else {
    // pc
    breakpointObj = {
        name: 'pc',
        min: 768,
        max: 999999,
        isMobile: false,
        isPc: true,
        prev: window.breakpoint || {}
    };
}
window.breakpoint = breakpointObj;

// 메인 성능 개선 - jquery passive event 적용
;(function($){
    $.event.special.touchstart = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchstart", handle, { passive: !(ns.indexOf('noPreventDefault') > -1) });  
        }
    };
    $.event.special.touchmove = {
        setup: function( _, ns, handle ) {
            this.addEventListener("touchmove", handle, { passive: !(ns.indexOf('noPreventDefault') > -1) });
        }
    };
    // $.event.special.wheel = {
    //     setup: function( _, ns, handle ){
    //         this.addEventListener("wheel", handle, { passive: true });
    //     }
    // };
    // $.event.special.mousewheel = {
    //     setup: function( _, ns, handle ){
    //         this.addEventListener("mousewheel", handle, { passive: true });
    //     }
    // };

    $.event.special.scroll = {
        setup: function( _, ns, handle ){
            this.addEventListener("scroll", handle, { passive: true });
        }
    };
})(jQuery);

;(function(global){

    if(global['lgkorUI']) return;
    // console.log("lgkorUI start!!!");

    var isApplication = isApp();
    var isAndroid = vcui.detect.isAndroid;
    var isIOS = vcui.detect.isIOS;
    var isMobileDevice = vcui.detect.isMobileDevice; 

    if(vcui.detect.isMac) $('html').addClass('mac');
    if(isApplication) $('html').addClass('app');
    if(isMobileDevice) $('html').addClass('mdevice');

    // // BTOCSITE-23753 PDP Page 이미지, 미디어 Lazyload 적용 ASIS
    // window.onload = function(){
    //     vcui.require([
    //         'ui/lazyLoaderSwitch',
    //         'ui/lazyLoader'
    //     ], function () {
    //         var $b = $('body');
    //         $b.vcLazyLoaderSwitch();
    //         $b.vcLazyLoader();
    //     });
    // };

    // BTOCSITE-23753 PDP Page 이미지, 미디어 Lazyload 적용 TOBE 
    $(document).ready(function(){
        if(exceptPath[location.pathname]){
            //console.log('lg.common.js > Lazyload 방지')
            return
        }
        vcui.require([
            'libs/lazyLoaderSwitchVideo.min',
            'ui/lazyLoaderSwitch',
            'ui/lazyLoader',
        ], function () {
            vcui.PubSub.trigger('onLazyLoaderBefore');
            var $b = $('body');
            $b.vcLazyLoaderSwitchVideo();
            $b.vcLazyLoaderSwitch();
            $b.vcLazyLoader();
        });
    });

    // BTOCSITE-429 앱 설치 유도 팝업 노출 페이지 추가 - 해당 요건으로인해 스크립트로 이동
    var appDownloadTmpl =
        '<article id="mobile-close-popup" class="popup-wrap small app-popup-init appMobile-pop">\n'+
        '    <section class="pop-conts align-center">\n'+
        '        <section class="section">\n'+
        '            <div class="appMobile-pop-content">\n'+
        '                <p class="appMobile-popImg"><img src="/lg5-common/images/MA/appPop_img_v2.png" alt="LG로고" class="pop-img"></p>\n'+
        '                <div class="text-cont">\n'+
        '                LG전자 <b>LGE.COM</b><br/>\n'+
        '                앱으로 더 편리하게<br/>\n'+
        '                이용하실 수 있습니다.\n'+
        '                </div>\n'+
        '            </div>\n'+
        '            <div class="btn-wrap">\n'+
        '                <button type="button" class="btn full border size-m" id="lg__app-download"><span>지금 앱으로 보기</span></button>\n'+
        '            </div>\n'+
        '        </section>\n'+
        '    </section>\n'+
        '    <button type="button" class="ui_modal_close">모바일 웹에서볼게요</button>\n'+
        '</article>\n';

    var alertTmpl =  '<article id="laypop" class="lay-wrap {{typeClass}}" style="display:block;" role="alert">\n'+
        '   <header class="lay-header">\n'+
        '       <h1 class="tit">{{#raw title}}</h1>\n'+
        '   </header>\n'+
        '   <section class="lay-conts ui-alert-msg">\n'+
        '   </section>\n'+
        '   <div class="btn-wrap laypop">\n'+
        //BTOCSITE-26960
        '{{#if opobsErrNm != null && opobsErrNm != undefined && opobsErrNm != "" }}'+
        '    <button type="button" class="btn ui_modal_close" data-role="ok" data-contents="{{opobsErrNm}}"><span>{{okBtnName}}</span></button>\n'+
        '{{#else}}' +
        '    <button type="button" class="btn ui_modal_close" data-role="ok"><span>{{okBtnName}}</span></button>\n'+
        '{{/if}}'+
        '   </div>\n'+
        '</article>';

    var mainPopupInit = 
    '<article id="main-init-popup" class="popup-wrap small main-init-popup"  style="max-width:500px">' + 
        '<header class="pop-header">' + 
            '<h1 class="tit"><span>안내</span></h1>' + 
        '</header>' + 
        '<section class="pop-conts" style="text-align:center;">' + 
            '<section class="section">' + 
                '<div class="headline">' + 
                    '<h3 class="h-tit-3">주주확정기준일설정공고</h3>' + 
                '</div>' + 
                '<div class="text-cont">' + 
                    '상법 제354조 및 우리 회사 정관 제21조에<br> 의거하여, ' + 
                    '다음과 같이 임시주주총회에서<br> 의결권을 행사할 주주 확정을 위한 ' + 
                    '기준일을<br> 정하였으니 양지하여 주시기 바랍니다. ' + 
                    '<br><br> - 다  음 - <br><br>' + 
                    '■주주확정기준일: 2021년 12월 10일<br><br>' +
                    '<p style="text-align:right;">2021년 11월 25일</p>' +
                    '<p style="text-align:right;">LG전자 주식회사</p>' +
                '</div>' + 
                '<div class="btn-wrap">' + 
                    '<a href="https://www.lge.co.kr/company/investor/announceView?anncmNo=18&page=1#com-tabs02" title="pdf 확인하기" class="btn full border size-m"><span>자세히 보기</span></a>' + 
                '</div>' + 
            '</section>' + 
        '</section>' + 
        '<div class="pop-footer check-type align-between">' + 
            '<span class="chk-wrap" data-role="today-cookie-check">' + 
                '<input type="checkbox" id="init-popup-check-today" name="init-popup-check-today">' + 
                '<label for="init-popup-check-today">7일동안 보지 않기</label>' + 
            '</span>' + 
            '<button type="button" class="btn pink btn-main-pop-close size"><span>닫기</span></button>' + 
        '</div>' + 
        '<button type="button" class="btn-close btn-main-pop-close"><span class="blind">닫기</span></button>' + 
    '</article>';

    // S: BTOCSITE-19181 시스템 점검 공지 안내 [UI] 시스템 점검 공지 안내
    // var mainPopupInit = 
    // '<article id="main-init-popup" class="popup-wrap small main-init-popup"  style="max-width:500px">' + 
    //     '<header class="pop-header">' + 
    //         '<h1 class="tit"><span>안내</span></h1>' + 
    //     '</header>' + 
    //     '<section class="pop-conts" style="text-align:center;">' + 
    //         '<section class="section">' + 
    //             '<div class="headline">' + 
    //                 '<h3 class="h-tit-3">시스템 점검 공지 안내</h3>' + 
    //             '</div>' + 
    //             '<div class="text-cont">' + 
    //                 '시스템 점검을 위해 홈페이지 서비스를<br>' + 
    //                 '일시적으로 중지합니다.<br><br>' + 
    //                 '상황에 따라서 작업시간이 단축 또는<br>' + 
    //                 '연장될 수 있는 점 양해 부탁드립니다. <br><br>' + 
    //                 '<strong>[ 점검일시 ]</strong><br>2022.08.17 22:00 ~ 08.18 05:00 (7시간)<br><br>' +
    //             '</div>' + 
    //         '</section>' + 
    //     '</section>' + 
    //     '<div class="pop-footer check-type align-between">' + 
    //         '<span class="chk-wrap" data-role="today-cookie-check">' + 
    //             '<input type="checkbox" id="init-popup-check-today" name="init-popup-check-today">' + 
    //             '<label for="init-popup-check-today">하루동안 보지 않기</label>' + 
    //         '</span>' + 
    //         '<button type="button" class="btn pink btn-main-pop-close size"><span>닫기</span></button>' + 
    //     '</div>' + 
    //     '<button type="button" class="btn-close btn-main-pop-close"><span class="blind">닫기</span></button>' + 
    // '</article>';
    // E: BTOCSITE-19181 시스템 점검 공지 안내 [UI] 시스템 점검 공지 안내

    var confirmTmpl =  '<article id="laypop" class="lay-wrap {{typeClass}}" style="display:block;" role="confirm">\n'+
        '   {{#if title}}'+
        '   <header class="lay-header">\n'+
        '       <h1 class="tit">{{#raw title}}</h1>\n'+
        '   </header>\n'+
        '   {{/if}}'+
        '   <section class="lay-conts ui-alert-msg">\n'+
        '   </section>\n'+
        '    <section class="lay-conts">\n'+
        '        <h6 class="ui-alert-msg"></h6>\n'+
        '    </section>\n'+
        '    <div class="btn-wrap laypop">\n'+
        '        <button type="button" class="btn gray ui_modal_close" data-role="cancel"><span>{{cancelBtnName}}</span></button>\n'+
        '        <button type="button" class="btn" data-role="ok"><span>{{okBtnName}}</span></button>\n'+
        '    </div>\n'+
        '</article>';


    $.fn.buildCommonUI = function () {
        vcui.require([
            'ui/selectbox',
            'ui/calendar',
            'ui/accordion',
            'ui/carousel',
            'ui/modal',
            'ui/tab',       
            "ui/videoBox",
            "ui/youtubeBox",
            "ui/dropdown",
            "ui/textControl",
            "ui/fileInput",
            "ui/radioShowHide",
            'ui/inputClearButton',
            "ui/starRating",
            "ui/tooltipTarget",
            "ui/sticky",
            "ui/formatter",
            "ui/scrollNavi",
            "ui/smoothScroll",
            "ui/smoothScrollTab",
            "ui/checkboxAllChecker",
            //"ui/imageSwitch"
            'ui/lazyLoaderSwitch',
            'ui/lazyLoader',
            'ui/stickyTab'
        ], function () {    
            // console.log("buildCommonUI!!!!");

            //this.vcImageSwitch();
            if(location.hostname == "cms50.lge.co.kr") {
                // console.log('lazy cms50');
                this.vcLazyLoaderSwitch();
                this.vcLazyLoader();
            }

            this.find('.ui_calendar').vcCalendar();
            this.find('.ui_accordion').vcAccordion();        
            this.find('.ui_dropdown').vcDropdown();
            this.find('.ui_tab').vcTab();
            this.find('.ui_carousel').vcCarousel();
            this.find('.animation-box').vcVideoBox();
            this.find('.youtube-box').vcYoutubeBox();
            this.find('.ui_textcontrol').vcTextcontrol();
            //this.find('.ui_fileinput').vcFileInput();
            this.find('.ui_radio_visible').vcRadioShowHide();
            this.find('.ui_input_clearbutton').vcInputClearButton();
            this.find('.ui_star_rating').vcStarRating();
            this.find('.ui_tooltip-target').vcTooltipTarget();
            
            //this.find('.ui_card_number').vcFormatter({format: "card", maxlength:16});
            
            this.find('.ui_smooth_scroll').vcSmoothScroll();
            this.find('.ui_scroll_navi').vcScrollNavi();

            this.find('.ui_smooth_scrolltab').vcSmoothScrollTab();

            this.find('.ui_sticky').vcSticky();

            this.find('.ui_all_checkbox').vcCheckboxAllChecker();

            this.find('.ui_selectbox').vcSelectbox({
                events:{
                    selectboxtoggle: function(e){
                        var selectwrap = $(e.currentTarget).siblings('.ui-selectbox-wrap');
                        var isOpen = selectwrap.hasClass('on');
                        if(isOpen){
                            var selectlist = selectwrap.find("> .ui-selectbox-list");
                            var margintop = selectlist.css('margin-top');
                            if(parseInt(margintop) < 0){
                                if(!selectwrap.hasClass('type_up')) selectwrap.addClass('type_up')
                            }
                        } else{
                            selectwrap.removeClass("type_up");
                        }
                    }
                }
            });

            this.find('.ui_wide_slider').vcCarousel({
                autoplay:true,
                autoplaySpped:5000,
                infinite: true,
                pauseOnHover:false,
                pauseOnFocus:false,
                swipeToSlide: true,
                buildDots:false,
                dotsSelector:'.ui_wideslider_dots',
                slidesToShow: 1,
                slidesToScroll: 1
            });
            this.find('.ui_wide_dot_slider').vcCarousel({
                autoplay:true,
                autoplaySpped:5000,
                infinite: true,
                pauseOnHover:false,
                pauseOnFocus:false,
                swipeToSlide: true,
                slidesToShow: 1,
                slidesToScroll: 1
            });
            this.find('.ui_carousel_4_slider').vcCarousel({
                infinite: false,
                autoplay: false,
                slidesToScroll: 4,
                slidesToShow: 4,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToScroll: 1,
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 20000,
                        settings: {
                            slidesToScroll: 4,
                            slidesToShow: 4
                        }
                    }
                ]
            });
            this.find('.ui_carousel_3_slider').vcCarousel({
                infinite: false,
                autoplay: false,
                slidesToScroll: 3,
                slidesToShow: 3,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            variableWidth: true,
                            slidesToScroll: 1,
                            slidesToShow: 1
                        }
                    },
                    {
                        breakpoint: 20000,
                        settings: {
                            slidesToScroll: 3,
                            slidesToShow: 3
                        }
                    }
                ]
            });
            this.find('.ui_carousel_1_slider').vcCarousel({
                infinite: false,
                autoplay: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                // responsive: [
                //     {
                //         breakpoint: 768,
                //         settings: {
                //             variableWidth: true,
                //             centerMode: true
                //         }
                //     }
                // ]
            });
            this.find('.ui_stickyTab').each(function(i){
                $(this).hasClass('no-init') || $(this).vcStickyTab();
            })
            lgkorUI.resetFlexibleBox();
        }.bind(this));

        return this;
    };

    

    /* 

    vcui.require.config({
        paths: {
            'jquery.transit': 'libs/jquery.transit'
        },
        waitSeconds: 15,
        onNodeCreated: function (node) {
            node.charset = 'euc-kr';
        }
    });
    

    // ajax 로 통신할때는 기본 케릭터셑이 utf-8 로 되어있음.
    // 따라서, euc-kr 로 설정된 서버에서 ajax 를 이용하여 한글로된 데이터를 전달하게 되면 글씨가 깨짐.
    // 이때는 아래와 같이 캐릭터셑을 euc-kr 로 설정해 주면 해결이 됩니다

    var pathname = location.pathname;
    if (pathname.indexOf('/html/') > -1) {
        console.log(pathname);
        $.ajaxSetup({
            contentType: 'application/x-www-form-urlencoded;charset=euc-kr',
            beforeSend: function (xhr) {
                xhr.overrideMimeType('application/x-www-form-urlencoded;charset=euc-kr');
            }
        });
    } 
    */
    
    $.holdReady(true);
    
    global['lgkorUI'] = {
        DEFAULT_URL : "",   // B2B 에서 사용됨
        B2B_PATH_URL: "/business",
        COMPARE_KEY: "prod_compare",
        CAREPLANER_KEY: "care_planer",
        CAREPLANER_ID: "putitem_list",
        CAREPLANER_PRICE: "putitem_price",
        MOBILE_CHECK_WIDTH: 768,
        STICKY_MODULES:[],
        NO_IMAGE: "/lg5-common/images/icons/noimage.svg",
        NO_IMAGE_MODEL_NAME: "/lg5-common/images/icons/noimage-modelName.svg",
        RECENT_PROD_COOKIE_NAME: "myRecentProduct", //최근 본 제품 쿠키
        COMPARE_COOKIE_NAME: "LG5_CompareCart", //비교하기 쿠키
        HOMEBREW_CHECK_COOKIE_NAME: "lgeAgeCheckFlag", //홈브류 연령체크 쿠키
        INTERGRATED_SEARCH_VALUE: "intergratedSearchValue",
        INTERGRATED_SEARCH_VALUE_B2B: "intergratedSearchValueB2B", // [BTOCSITE-50530] [검색] B2B OBS 구축
        MAX_SAVE_RECENT_KEYWORD: 10, //최근 검색어 저장 최대수// [BTOCSITE-59845] 검색어 입력 개선
        MAX_SAVE_RECENT_KEYWORD_B2B: 5, //최근 검색어 저장 최대수 B2B// [BTOCSITE-59845] 검색어 입력 개선
        MAX_SAVE_RECENT_PRODUCT: 10, //최근 본 제품 저장 최대수,
        SEARCH_AUTOCOMPLETE_MIN_LENGTH: 1, // 검색 자동 완성 기능 실행 최소 글자수
        SEARCH_AUTOCOMPLETE_TIMER: 300, // 검색 자동 완성 기능 키보드 클릭 타이머
        DOMAIN_LIST: [
            "www.lge.co.kr",
            "wwwstg.lge.co.kr",
            "wwwdev50.lge.co.kr",
            "brand.lge.co.kr",
            "brandstg.lge.co.kr",
            "branddev.lge.co.kr",
            "test.lge.co.kr",
            "localhost",
            "dti.lge.co.kr",
            "stgdti.lge.co.kr",
            "devdti.lge.co.kr",
            "devmdti.lge.co.kr",
        ],
        CONTEXT_AREA: null,
        CHAT_PIN_CODE : null,
        CHATBOT_SERVICE_LIST: ["제품간단조치방법", "구매전문가상담"],  // [BTOCSITE-39081] 2.0 챗봇 연계 
        init: function( $context ){
            var self = this;

            // B2B 비교하기 키값 변경
            if(location.pathname.indexOf(self.B2B_PATH_URL + "/") > -1) {
                self.DEFAULT_URL = self.B2B_PATH_URL ;
                self.COMPARE_KEY = "prod_compare_b2b";
            }

            self._bindErrBackEvent();
            self._addImgOnloadEvent();

            if (!!$context){
                self.CONTEXT_AREA = $context;
                self._preloadComponents();
            } else {
                //self.CONTEXT_AREA = null;
                self.CONTEXT_AREA = $(document);
                self._preloadComponents();
            }

            //self._mobileInitPopup(); 
            self._addTopButtonCtrl();
            self._createMainWrapper();
            self._switchLinker();

            var lnbContents = $('.contents .lnb-contents');
            if(lnbContents.length) lnbContents.attr('id', 'content');
            else $('body').find('.container').attr('id', 'content');
            if (!!$context){
                return $.Deferred().resolve($context.data());
            }
            void 0;
            if(isApp() || lgkorUI.getParameterByName('emde') !== undefined) self.integrateLoginEvent();  // BTOCSITE-13955 ThinQ LGE.com 앱간 자동 로그인 연계

            var curUrl = lgkorUI.parseUrl(location.href);
            if(curUrl.pathname === '/my-page/manage-products') self.checkIntegrateEvent();  // BTOCSITE-13955 ThinQ LGE.com 앱간 자동 로그인 연계

            // BTOCSITE-15718 엘라쇼 라이브 화면 유지
            var beforeUrl = document.referrer;
            var nowUrl = window.location.href;
            if(vcui.detect.isMobile) {
                if(!isApp()) {
                    // BTOCSITE-26953 : 웹pip 관련(세션내 모든 페이지에서 웹 pip 유지)
                    var sessionBroadcastId = sessionStorage.getItem("broadcastId");
                    var paramBroadcastId = lgkorUI.getParameterByName('broadcastId');

                    if(sessionBroadcastId && (beforeUrl.indexOf("broadcastId") == -1 && nowUrl.indexOf("livecommerce/") == -1)) {
                        var broadcastId  = sessionBroadcastId;
                        if(paramBroadcastId) {
                            broadcastId = paramBroadcastId;
                            window.sessionStorage.setItem("broadcastId", broadcastId);
                        }
                        self.checkBroadCast(broadcastId);
                    } else if(paramBroadcastId) {
                        window.sessionStorage.setItem("broadcastId", paramBroadcastId);
                        self.checkBroadCast(paramBroadcastId);
                    }
                }
            }

            // BTOCSITE-3057 청약 신청화면에서 사이트 이탈 시 로딩 수정
            $(window).on("beforeunload",function(){

                self.hideLoading()
            });

        },


        //BTOCSITE-429 앱 설치 유도 팝업 노출 페이지 추가
        _appDownloadPopup: function() {
            var enableUrl = [
                '^/$', // 메인
               '^/benefits/event/?', // 이벤트 페이지
               '^/benefits/exhibitions/?' // 기획전 페이지
            ];

            var isPopUp = enableUrl.some(function(element) {
                return location.pathname.match(new RegExp(element,"g"))
            })



            $(function() {
                if (vcui.detect.isMobileDevice && !isApp() && navigator.userAgent.toLocaleLowerCase().indexOf("kakaotalk") < 0) {
                    var cookie_name = '__LGAPP_DLOG__';
                    if (vcui.Cookie.get(cookie_name) === '' && isPopUp ) {
                        if($('#mobile-close-popup').size() === 0 && !!vcui.modal) {
                            $('body').append(vcui.template(appDownloadTmpl));
                            vcui.modal('#mobile-close-popup', open);
                            var el = $('#mobile-close-popup');
                            el.find('#lg__app-download').on('click', function () {
                                goAppUrl();
                                return;
                            });
                            el.find('.ui_modal_close').one('click', function () {
                                vcui.Cookie.set(cookie_name, 'hide', {"expires": 1, "path": '/'});
                                $('html, body').css('overflow', '');
                                return;
                            });
                        }

                    }
                }
            });
        },
        _mobileInitPopup: function(){
            var enableUrl = [
                '^/$', // 메인
            ];

            var isPopUp = enableUrl.some(function(element) {
                return location.pathname.match(new RegExp(element,"g"))
            });

            $(function() {
                if (!isApp()) {
                    var cookie_InitPopName = '__LG_MAIN_REPORT_POPUP_INIT';
                    if (vcui.Cookie.get(cookie_InitPopName) === '' && isPopUp ) {
                        if($('#main-init-popup').size() === 0 && !!vcui.modal) {
                            $('body').append(vcui.template(mainPopupInit));
                            $('#main-init-popup').vcModal('show');

                            $(document).on('click', '#main-init-popup .btn-main-pop-close', function (e) {
                                var _expireChecked = $('#main-init-popup').find('.check-type input:checkbox').prop('checked');

                                if( _expireChecked ) {
                                    vcui.Cookie.set(cookie_InitPopName, 'hide', {"expires": 7, "path": '/'});
                                }
                                $('#main-init-popup').vcModal('hide');
                                /* BTOCSITE-2148:pc메인 페이지 수정 2021-07-23 */ 
                                $('html, body').css('overflow', '');
                                /* BTOCSITE-2148:pc메인 페이지 수정 2021-07-23 */
                                return;
                            });
                        }

                    }
                }
            });
        },
        _addImgOnloadEvent: function(){
            var self = this;
            $('img').not('[data-pc-src]').on('error', function(e){
                var img = this;
                img.onerror = null;
                if ( !img.complete || !img.naturalWidth ) {
                    img.src = self.NO_IMAGE;
                    img.classList.add('no-img');
                }
                /*
                $(this).off('error');
                $(this).attr('src', self.NO_IMAGE);
                $(this).addClass('no-img');
                */
            });
        },

        addImgErrorEvent: function(img){
            var self = this;
            img.onerror = null;
            if ( !img.complete || !img.naturalWidth ) {
                img.src = self.NO_IMAGE;
                img.classList.add('no-img');
            }
        },

        /**
         * addImgErrorEvent() 전 상품 svg 일러스트 로드 시도 함수
         *          * BTOCSITE-19567 noImageUrl 카테고리 대체 이미지
         * @param {element} img 이미지 element
         * @param {string} cate 카테고리 ID (e.g. CT50000143)
         */
        addImgCateErrorEvent: function(img, cate) {
            var self = this;

            var imgElm = document.createElement('img');
            var categoryId = cate || '';

            // pc는 원형 border 가 포함되어있어 모바일 이미지만 사용하기로
            imgElm.src = categoryId;

            imgElm.onload = function () {
                img.src = imgElm.src;

                // 회색 라운드 백그라운드 style class
                if (img.parentElement.tagName.toLocaleLowerCase() === 'a') {
                    img.parentElement.classList.add('cata_noimg');
                }
            }

            imgElm.onerror = function () {
                self.addImgErrorEvent(img);
            }
        },

        addModelNameImgErrorEvent: function(img){
            var self = this;
            img.onerror = null;
            $(img).attr('src', self.NO_IMAGE_MODEL_NAME);
            $(img).addClass('no-img');
        },


        _createMainWrapper: function(){
            if ( $('.container-fluid:has(.header)').length && !$('main').length ) {
                //$('.container-fluid:has(.header) ~ div,.container-fluid.iw_section:has(.header) ~ section').not(':has(.footer-box)').wrapAll('<main></main>');
            }
        },
    
        // 주요 컴포넌트를 미리 로드
        _preloadComponents: function () {
            var self = this;
            var commonHeader = ( location.pathname.indexOf('/business') == 0
                || (location.pathname.indexOf('/benefits/exhibitions/') >= 0 && 'Y' == new URL(location.href).searchParams.get('b2bYn'))
                || location.pathname.indexOf('/html/EDM/') >= 0 ) ? 'business/header' : 'common/header';
            vcui.require([  
                //'helper/responsiveImage',
                //'helper/breakpointDispatcher',
                'ui/spinner',         
                'ui/selectbox',
                'ui/calendar',
                'ui/accordion',
                'ui/carousel',
                'ui/modal',
                'ui/tab',       
                "ui/videoBox",
                "ui/youtubeBox",
                "ui/textControl",
                "ui/fileInput",
                "ui/radioShowHide",
                'ui/inputClearButton',
                "ui/starRating",
                "ui/tooltipTarget",
                "ui/toast",
                "ui/sticky",
                "ui/formatter",
                "ui/scrollNavi",
                "ui/smoothScroll",
                "ui/smoothScrollTab",
                'ui/imageFileInput',
                commonHeader, 
                'common/footer',
            ], function (/*ResponsiveImage,*/ /*BreakpointDispatcher*/) {
                
                // new BreakpointDispatcher({
                //     matches: {
                //         '(min-width: 768px)' : function(mq) {
                //             var data;
                //             if (mq.matches) {
                //                 // pc
                //                 data = {
                //                     name: 'pc',
                //                     min: 768,
                //                     max: 999999,
                //                     isMobile: false,
                //                     isPc: true,
                //                     prev: window.breakpoint || {}
                //                 };
                                
                //             } else {
                //                 // mobile
                //                 data = {
                //                     name: 'mobile',
                //                     min: 0,
                //                     max: 767,
                //                     isMobile: true,
                //                     isPc: false,
                //                     prev: window.breakpoint || {}
                //                 };
                //             }
    
                //             window.breakpoint = data;
                //             $(window).data('breakpoint', data).trigger('breakpointchange', data);
                //         }
    
                //         /* 
                //         '(min-width : 769px) and (max-width : 1599px)' : function(mq){
                //         },
                //         '(min-width : 1600px)' : function(mq){
                //         } 
                //         */
                //     }
                // }).start();     
                
                /*
                var breakpoint = {
                    mobile: 768,
                    pc: 100000
                }
                
                new ResponsiveImage('body', breakpoint);
                */
                
                var $doc = $(document);                       

                //resize 이벤트 발생 시 등록 된 이벤트 호출...
                $(window).off('resizeend.flexible-box').on('resizeend.flexible-box', function(e){
                    self.resetFlexibleBox();
                });  
                self.resetFlexibleBox();

                /*
                self.resizeCallbacks = [];
                $(window).on("addResizeCallback", function(e, callback){
                    self.resizeCallbacks.push(callback);
                }).on('resize', function(e){
                    for(var idx in self.resizeCallbacks){
                        self.resizeCallbacks[idx].call();
                    }

                    //self._switchLinker();

                    self.resetFlexibleBox();
                });  
                self.resetFlexibleBox();
                */

                // 모달 기초작업 //////////////////////////////////////////////////////
                // 모달 기본옵션 설정: 모달이 들때 아무런 모션도 없도록 한다.(기본은 fade)
                vcui.ui.setDefaults('Modal', {
                    effect: 'fade',         // 모달이 뜰 때 사용할 효과
                    draggable: false,       // 모달을 드래그 할 수 있게 허용할 것인가..no
                    closeByEscape: false,   // esc키 누를 때 닫히게 할 것인가
                    closeByDimmed: false,   // dim 클릭시 닫히게 할 것인가
                    events: {
                        modalshown: function (e) {
                            // 모달이 뜨면 내부 컨텐츠 영역이 포커싱되도록 tabindex를 설정
                            //this.$('.pop_contents').attr('tabindex', 0);
                            //console.log(this);

                            // $('html, body').css({
                            //     overflow:"hidden"
                            // });
    
                            if(this.$('.ui_carousel').length>0){
                                this.$('.ui_carousel').vcCarousel('update');
                            }
                            if(this.$('.ui_smooth_scrolltab').length>0){
                                this.$('.ui_smooth_scrolltab').vcSmoothScrollTab('refresh');
                            }
                            if(this.$('.ui_smooth_scroll').length>0){
                                this.$('.ui_smooth_scroll').vcSmoothScroll('refresh');
                            }

                            if(this.$('.ui_pop_sticky').length > 0){
                                this.$('.ui_pop_sticky').vcSticky();
                            }
                        },
                        modalhidden: function(e){
                            var $modal = $(e.currentTarget);
                            var $opener = $modal.vcModal('getOpener');
                            if($opener) $opener.focus();

                            // $('html, body').css({
                            //     overflow:"visible"
                            // });
                        }
                    }
                });

                //캐로우셀
                vcui.ui.setDefaults('Carousel', {
                    events: {
                        carouselinit: function (e, data) {   
                            if(data.$el.find('.youtube-box').length>0) {                      
                                data.$el.find('.youtube-box').vcYoutubeBox();
                            }   
                            if(data.$el.find('.animation-box').length>0) {                      
                                data.$el.find('.animation-box').vcVideoBox();
                            }      
                        }
                    }
                });
    
                // 아코디언의 기본설정을 멀티오픈으로 설정해놓는다,
                vcui.ui.setDefaults('Accordion', {
                    singleOpen: false,
                    events: {
                        accordionexpand: function (e, data) {
                            // data.content.attr('tabindex', '0');                  
                            if(data.content.find('.ui_carousel').length>0) {                                
                                data.content.find('.ui_carousel').vcCarousel('update');
                            }                            
                        }
                    }
                });
    
    
                // 탭의 기본설정을 설정해놓는다,
                vcui.ui.setDefaults('Tab', {
                    events: {
                        tabchange: function (e, data) {
                            if(data && data.content.find('.ui_carousel').length > 0) {
                                data.content.find('.ui_carousel').vcCarousel('update');
                            }
                            if(data && data.content.find('.ui_smooth_scrolltab').length>0){
                                data.content.find('.ui_smooth_scrolltab').vcSmoothScrollTab('refresh');
                            }
                            if(data && data.content.find('.ui_smooth_scroll').length>0){
                                data.content.find('.ui_smooth_scroll').vcSmoothScroll('refresh');
                            }
                            if(data && data.content.find(".ui_carousel_slider").length > 0){
                                // BTOCSITE-66272
                                // .ui_carousel_slider는 초기화 후에 carousel.js 에서 붙이는 css 클래스로 위 .ui_carousel 와 중복이며
                                // 아래 update 호출을 하더라도 display none 상태에서 호출되면 .slide-track 가 width: 0 되는 문제가 있어
                                // 불필요하게 block 처리 후 다시 초기화를 해줘야하는 문제가 있습니다.
                                // 
                                // .not(':hidden') 추가하여 보이는 요소만 update 하도록 수정 합니다.
                                // 위 .ui_carousel 와 중복이나 기존 컨텐츠 영향도 생각하여 삭제는 하지 않겠습니다.
                                // 
                                // e.g. 탭 컨텐츠 내부 .ui_accordion 존재하면 닫힌 아코디언 컨텐츠는 display: none 이므로..
                                data.content.find('.ui_carousel_slider').not(':hidden').vcCarousel('update');
                            }
                        }
                    }
                });

                if (!!lgkorUI.CONTEXT_AREA){
                    $('header.header').vcHeader(); //헤더 모듈 적용...
                    //lgkorUI.CONTEXT_AREA.find('footer').vcFooter(); //푸터모듈 적용...
                    lgkorUI.CONTEXT_AREA.find('footer:not(.pop-footer)').vcFooter();

                    lgkorUI.CONTEXT_AREA.buildCommonUI();

                } else {
                    $('header.header').vcHeader(); //헤더 모듈 적용...
                    //$('footer').vcFooter();
                    $('footer:not(.pop-footer)').vcFooter();

                    $('body').buildCommonUI();
                }

    
                $.holdReady(false); // ready함수 실행을 허용(이전에 등록된건 실행해준다.)
    
                // 모달이 열렸을 때 페이지 스크롤을 막기 위함 ////////////////////////////
                $doc.on('modalfirstopen modallastclose', function (e) {
    
                }).on('modalshown', function (e) {
                    // 모달이 뜰때 모달내부에 있는 공통 컴포넌트 빌드
                    $(e.target).buildCommonUI();
                    self.resetFlexibleBox();
                });
                //////////////////////////////////////////////////////////////////////
    
                // 아코디온이 펼쳐지거나 닫힐 때 레이아웃 사이즈가 변하기 때문에 resize이벤트를 강제로 발생시킨다.
                // $doc.on('accordionexpand accordioncollapse', vcui.delayRun(function (e) {
                //     $(window).triggerHandler('resize');
                // }, 200));
                ///////////////////////////////////////////////////////////////////////

                //공통 js-pop a태그 처리...
                $doc.off('click.jsPop').on('click.jsPop', '.js-popup', function(e){
                    e.preventDefault();

                    /* BTOCSITE-38600 챗봇 아이콘 변경 및 활성화 Start */
                    var component = this.getAttribute('data-component');
                    var target = this.getAttribute('href');

                    if (component === "KRP0005" || component === "KRP0013") {
                        target = this.getAttribute('data-url');
                    }
                    /* BTOCSITE-38600 챗봇 아이콘 변경 및 활성화 End */

                    var popupWidth = parseInt(this.getAttribute('data-width')),
                        popupHeight = parseInt(this.getAttribute('data-height')),
                        screenWidth = parseInt(screen.width),
                        screenHeight = parseInt(screen.height),
                        intLeft = Math.floor((screenWidth - popupWidth) / 2),
                        intTop = Math.floor((screenHeight - popupHeight) / 2);
            
                    if (intLeft < 0) intLeft = 0;
                    if (intTop < 0) intTop = 0;
            
                    if(target.indexOf('chat.lge.co.kr') > 0 || target.indexOf('lgechat.co.kr') > 0) {
                        // 챗봇 링크인경우 처리
                        if (!isApp()) {
                            lgkorUI.getChatPinCode(target, popupWidth, popupHeight, intLeft, intTop);
                        }
                    } else{
                        window.open(target, '_blank', 'width=' + popupWidth + ',height=' + popupHeight + ',left=' + intLeft + ',top=' + intTop + ',history=no,resizable=no,status=no,scrollbars=yes,menubar=no');
                    }  
                });

                // BTOCSITE-3536
                /**
                 *   a 태그 속성에 data-go-url 추가하여 사용하세요
                 *  - 모바일 브라우져 동작시 target 설정값이 '_blank' => window.open '_self' 이거나 미설정시 => location.href 이동
                 *  - 아래 옵션은 app 일경우만 동작 입니다.
                 *  - 옵션 : data-open-mode
                 *      미설정시 : 앱내 이동 location.href 로 이동 시킵니다.
                 *      inAppBrowser : inAppBrowser 로 뛰움
                 *      outlink  : ios , android 외부 브라우저로 뛰움
                 *    <샘플>
                 *    <a data-go-url data-open-mode="outlink" href="https://www.lge.co.kr/story/user-guide/objetcollection-change-panel-guide">자세히 보기</a>
                 * 
                 */
                $doc.off('click.goUrl').on('click.goUrl', '[data-go-url]', function(e){
                    e.preventDefault();
                    lgkorUI.goUrl(this);             
                });

                $('.toast-message').remove();
                $('body').append('<div class="toast-message"></div>');
                $('.toast-message').vcToast();
            });

            vcui.require([
                'helper/breakpointDispatcher'
            ], function (BreakpointDispatcher) {
                new BreakpointDispatcher({
                    matches: {
                        '(min-width: 768px)' : function(mq) {
                            var data;
                            if (mq.matches) {
                                // pc
                                data = {
                                    name: 'pc',
                                    min: 768,
                                    max: 999999,
                                    isMobile: false,
                                    isPc: true,
                                    prev: window.breakpoint || {}
                                };
                                
                            } else {
                                // mobile
                                data = {
                                    name: 'mobile',
                                    min: 0,
                                    max: 767,
                                    isMobile: true,
                                    isPc: false,
                                    prev: window.breakpoint || {}
                                };
                            }
    
                            window.breakpoint = data;
                            $(window).data('breakpoint', data).trigger('breakpointchange', data);
                        }
    
                        /* 
                        '(min-width : 769px) and (max-width : 1599px)' : function(mq){
                        },
                        '(min-width : 1600px)' : function(mq){
                        } 
                        */
                    }
                }).start();
            });

            self.loadKakaoSdkForShare();
        },

        //top 버튼 컨틀롤...
        _addTopButtonCtrl: function(){
            var self = this;
            var $floatingTop = $('.floating-menu.top');

            var _rafRun = function (cb) {
                var tick = false
              
                return function trigger() {
                  if (tick) {
                    return
                  }
              
                  tick = true
                  return requestAnimationFrame(function task() {
                    tick = false
                    return cb()
                  })
                }
            }
           
            $(window).scroll(_rafRun(function(){
                // BTOCSITE-39174 챗봇아이콘 관련 개선 사항 - setTimeout 0.2초 제거
                if (window.pageYOffset > 0) {
                    $(window).trigger('floatingTopShow');
                } else {
                    $(window).trigger('floatingTopHide');          
                }
            }));

            // BTOCSITE-12128 메인성능개선
            $(window).on('floatingTopHide', function(e){
               if(!$floatingTop.hasClass('call-yet')) $floatingTop.addClass('call-yet');
            }); 

            $(window).on('floatingTopShow', function(e){
                if($floatingTop.hasClass('call-yet')) $floatingTop.removeClass('call-yet');
            }); 
            
            $floatingTop.on('click','button', function (e) {
                e.preventDefault();
                $(window).trigger('floatingTop');
                $('html, body').stop().animate({
                    scrollTop: 0
                }, 400);
            });
        },

        _switchLinker: function(){
            $('body').find('a[data-pc-href]').each(function(idx, item){
                var href = vcui.detect.isMobileDevice ? $(item).data("mHref") : $(item).data("pcHref");
                $(item).attr('href', href);
            });
        },

        //에러 페이지 되돌아가기
        _bindErrBackEvent: function(){
            var self = this;

            $('body').find('.contents.error-page .btns a').on('click', function(e){
                e.preventDefault();

                self._historyBack();
            })
        },

        _historyBack: function(){
            var self = this;
            
            var referrer = document.referrer;
            var index = -1;
            var leng = lgkorUI.DOMAIN_LIST.length;
            for(var i=0;i<leng;i++){
                index = referrer.indexOf(lgkorUI.DOMAIN_LIST[i]);
                if(index > -1){
                    break;
                }
            }

            // B2B 여부
            var isB2b = location.href.indexOf("/business/") > -1 || location.pathname == "/business"
                || 'Y' == new URL(location.href).searchParams.get('b2bYn') ;

            // BTOCSITE-3536 앱이고 파라메타에 openInApp 있는경우 closeInAppBrowser 실행
            if(isApp() &&  lgkorUI.getParameterByName('openMode') === 'inAppBrowser') {
                if(vcui.detect.isIOS){ 
                    var jsonString = JSON.stringify({'command':'closeInAppBrowser'});
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                 }else{
                     android.closeNewWebview(); 
                 }
            } else {
                if(opener && history.length <= 1) {
                    window.close(); // BTOCSITE-18048
                } else {
                    if(index < 0) {
                        if(isB2b) {
                            location.href = "/business";
                        }else {
                            location.href = "/";
                        }
                    }else{
                        history.back();
                    }
                }
            }
        },

        resetFlexibleBox: function(){
            //리스트 height 재설정
            $('body').find('.ui_flexible_height').each(function(idx, item){
                var maxheight = 0;
                $(item).children().each(function(cdx, child){
                    var flexiblebox = $(child).find('.ui_flexible_box .ui_flexible_cont');
                    maxheight = Math.max(maxheight, flexiblebox.outerHeight(true));
                });

                $(item).find('.ui_flexible_box').height(maxheight);
            });
        },

        showLoading:function(msg, target){
            vcui.require(['ui/spinner'],function(){
                var str = msg? msg : '';
                var $target = target instanceof $ ? target : $(target);

                if($target[0]){
                    $target.vcSpinner({msg:str, position:'absolute'}).vcSpinner('spin'); 
                }else{
                    // $('html').addClass('dim');
                    $('body').append("<div class='loading_dim' style='position:fixed;width:100%;height:100%;left:0;top:0;background:rgba(255,255,255,.5);z-index:199999999'></div>") // BTOCSITE-57393 [디자인] 닷컴 전체 로딩 모션, Dim 개선
                    $('body').vcSpinner({msg:str, position:'fixed'}).vcSpinner('spin'); 
                }
                
            }); 
        },

    
        hideLoading:function(target){
            vcui.require(['ui/spinner'],function(){

                var $target = target instanceof $ ? target : $(target);

                if($target[0]){
                    $target.vcSpinner('stop');
                }else{
                    $('.loading_dim').remove();
                    // $('html').removeClass('dim');
                    $('body').vcSpinner('stop');
                }
                
            });
        },

        confirm:function () {
            /**
             * 얼럿레이어
             * @memberOf vcui.ui
             * @name confirm
             * @function
             * @param {string} msg 얼럿 메세지
             * @param {Object} options 모달 옵션
             * @param {Element} opener 포커스 타겟
             * @example
             * lgkorUI.confirm('받은 알림을 전체삭제<br>하시겠습니까?', {                        
                ok:function(){},
                cancel:function(){}
                });
             */
            return function (msg, options, opener) {
            
                if (typeof msg !== 'string' && arguments.length === 0) {
                    options = msg;
                    msg = '';
                }
    
                var callbackOk, callbackCancel;
    
                if(options && options.ok && typeof options.ok =='function'){
                    callbackOk = options.ok;
                    delete options['ok'];
                } 
                if(options && options.cancel && typeof options.cancel =='function'){ 
                    callbackCancel = options.cancel;
                    delete options['cancel'];
                }
    
                $('html').addClass('dim');
                var tmplObj = {
                    cancelBtnName:options && options.cancelBtnName? options.cancelBtnName:'취소' ,
                    okBtnName:options && options.okBtnName? options.okBtnName:'확인' ,
                    typeClass:options && options.typeClass? options.typeClass:'' ,
                    title:options && options.title? options.title:''
                }
                var el = $(vcui.template(confirmTmpl, tmplObj)).appendTo('body');
                if(tmplObj.title) $(el).find('.lay-conts.ui-alert-msg').html(msg), $(el).find('.lay-conts:not(.ui-alert-msg)').remove();
                else $(el).find('.lay-conts h6.ui-alert-msg').html(msg), $(el).find('.lay-conts.ui-alert-msg').remove();
                

                var modal = $(el).vcModal(vcui.extend({ removeOnClose: true, variableHeight:true, variableWidth:true, isHash:false, alertType:true, opener:opener}, options)).vcModal('instance');
                modal.getElement().buildCommonUI();
                modal.on('modalhidden modalok modalcancel', function (e) {    
                    if(e.type =='modalok'){
                        if(callbackOk) callbackOk.call(this, e);
                    }else if(e.type == 'modalcancel'){
                        if(callbackCancel) callbackCancel.call(this, e);
                    }    
                    $('html').removeClass('dim');
                    el = null;
                    modal = null;
                });
                return modal;
            };
        }(),
    
        alert:function () {
            /**
             * 얼럿레이어
             * @memberOf vcui.ui
             * @name alert
             * @function
             * @param {string} msg 얼럿 메세지
             * @param {Object} options 모달 옵션
             * @param {Element} opener 포커스 타겟
             * @example
             * lgkorUI.alert('<p>구매일자 : 2020. 06. 18</p><p>구매제품 : 얼음정수기냉장고</p>', {
                 title:'영수증 등록이 완료되었습니다.',
                 ok:function(){}
                }, this);
             *  
             */
    
            return function (msg, options, opener) {
                if (typeof msg !== 'string' && arguments.length === 0) {
                    options = msg;
                    msg = '';
                }
    
                var callbackOk;
    
                if(options && options.ok && typeof options.ok =='function'){
                    callbackOk = options.ok;
                    delete options['ok'];
                } 
    
                $('html').addClass('dim');

                var el = $(vcui.template(alertTmpl, {
                    okBtnName:options && options.okBtnName? options.okBtnName:'확인' ,
                    typeClass:options && options.typeClass? options.typeClass:'' ,
                    title:options && options.title? options.title:'',
                    //BTOCSITE-26960
                    opobsErrNm: options && options.opobsErrNm? options.opobsErrNm:''
                })).appendTo('body');
                $(el).find('.ui-alert-msg').html(msg);                

                var modal = $(el).vcModal(vcui.extend({ removeOnClose: true, variableHeight:true, variableWidth:true ,isHash:false, alertType:true, opener:opener}, options)).vcModal('instance');
                modal.getElement().buildCommonUI();
                modal.on('modalhidden modalok', function (e) {
    
                    if(e.type =='modalok'){
                        if(callbackOk) callbackOk.call(this, e);
                    }
                    $('html').removeClass('dim');
                    el = null;
                    modal = null;
                });
                return modal;
            };   
            
        }(),

        getCompareLimit: function(){
            return window.breakpoint.isMobile ? 2 : 3;
        },

        addCompareProd: function(categoryId, data, ctName){
            var self = this;

            var compareLimit = self.getCompareLimit();
            var compareStorage = self.getStorage(self.COMPARE_KEY);
            var paramDataData = { 'name' : categoryId };

            if(compareStorage[categoryId] == undefined){
                var categoryName;

                // LGECOMVIO-15-compare 통합검색쪽 비교하기 카테고리명 getHiddenInputData로 전달할 수 없어 분기 추가함.
                if(ctName) {
                    categoryName = ctName;
                } else {
                    categoryName = lgkorUI.getHiddenInputData().categoryName;
                }

                compareStorage[categoryId] = { 'categoryName' : categoryName, 'data' : [data]};

                if(compareStorage[categoryId]['data'].length === 1){
                    // 비교하기 첫제품 추가시 토스트 메시지
                    // $(window).trigger("toastshow", "선택하신 제품과 함께 비교할 수 있는 제품만 비교하기 버튼이 보여집니다");

                    // 비교하기 버튼 상태 변경
                    if($('.KRP0007').size() > 0) {
                        $('.KRP0007 a[data-b2bcatemapping]').removeAttr('style')
                        .parent().find('a[data-b2bcatemapping="'+(data.b2bcatemapping === 'Y' ? 'N' : 'Y')+'"]').hide();
                    }

                }

            } else{
                var leng = compareStorage[categoryId]['data'].length;
                if(leng < compareLimit){
                    compareStorage[categoryId]['data'].push(data);
                } else{
                    $(window).trigger(jQuery.Event('excessiveCompareStorage', paramDataData));
                    return false;
                }
            }

            // 세션스토리지에서 비교하기 데이터 전체 비교
            var prod_compare = lgkorUI.getStorage(lgkorUI.COMPARE_KEY);
            if(prod_compare.hasOwnProperty(categoryId) && prod_compare[categoryId].data.length) {
                var cateMapCheck = true;
                prod_compare[categoryId].data.forEach(function(item) {
                    if(item.b2bcatemapping !== data.b2bcatemapping) {
                        cateMapCheck = false;
                        return false;
                    }
                });

                if(!cateMapCheck) {
                    void 0;
                    return false;
                }
            }
            // BTOCSITE-38770
            self.setStorage(self.COMPARE_KEY, compareStorage, true, categoryId);

            return true;
        },

        removeCompareProd: function(categoryId, id){
            var self = this;

            if(id) {
                var compareStorage = self.getStorage(self.COMPARE_KEY);
                compareStorage[categoryId]['data'] = vcui.array.filter(compareStorage[categoryId]['data'], function(item){
                    return item['id'] != id;
                });

                if(compareStorage[categoryId]['data'].length == 0) {
                    self.removeStorage(self.COMPARE_KEY, categoryId);

                    // PLP 비교하기 버튼 리셋
                    if($('.KRP0007').size() > 0) {
                        $('.KRP0007 a[data-b2bcatemapping]').removeAttr('style');
                    }
                    
                    // PDP 비교하기 아이템 삭제시 버튼 상태 변경
                    if($('.KRP0008').size() > 0) {
                        $('.KRP0008 .product-compare').removeAttr('style');
                    }

                } else {
                    var data = {};
                        data[categoryId] = compareStorage[categoryId];
                    self.setStorage(self.COMPARE_KEY, data, true, categoryId);
                }

            } else {
                // self.initCompareProd(categoryId);
                self.removeStorage(self.COMPARE_KEY, categoryId);

                // 비교하기 버튼 리셋
                if($('.KRP0007').size() > 0) {
                    $('.KRP0007 a[data-b2bcatemapping]').removeAttr('style');
                }

                // PDP 비교하기 아이템 삭제시 버튼 상태 변경
                if($('.KRP0008').size() > 0) {
                    // $('.KRP0008 .product-compare input[type=checkbox]').removeAttr('disabled');
                    $('.KRP0008 .product-compare').removeAttr('style');
                }
            }
        },

        // initCompareProd: function(categoryId){
        //     var self = this;
            
        //     self.removeStorage(self.COMPARE_KEY, categoryId);
        //     // 비교하기 버튼 리셋
        //     $('.KRP0007 a[data-b2bcatemapping]').removeAttr('style');
        // },

        setCompapreCookie: function(categoryId){
            var self = this;
            var compareIDs = [];
            var compareStorage = self.getStorage(self.COMPARE_KEY, categoryId);
                compareStorage['data'].forEach(function(item){ compareIDs.push(item.id + '|' + item.careType); }); // BTOCSITE-5938-545 care type 추가
            var compareCookie = compareIDs.join(",");

            self.setCookie(self.COMPARE_COOKIE_NAME, compareCookie);
        },

        addEqualCompare: function(ids, url){
            var self = this;

            self.setCookie(self.COMPARE_COOKIE_NAME, ids);

            location.href = url;
        },

        showCompareBox: function(categoryId){
            var data = { 'name' : categoryId };
            $(window).trigger(jQuery.Event("showCompareBox", data));
            return false;
        },

        setStorage: function(key, value, isExtend, name){
            var storage = sessionStorage.getItem(key);
            var storageData = storage? JSON.parse(storage) : {};
            var data = { 'state' : 'set', 'key' : key, 'value' : value };
            //Internet Explorer 불가
            //storageData = Object.assign(storageData, value);
            if(isExtend) {
                $.extend(storageData, value);
            } else {
                storageData = value;
            }
            sessionStorage.setItem(key, JSON.stringify(storageData));
            if(name) data = $.extend(data, { 'name' : name});
            $(window).trigger(jQuery.Event("changeStorageData", data));

            return storageData;
        },

        getStorage: function(key, name){							
            var storage = sessionStorage.getItem(key); 
            if(name){							
                var storageData = storage? JSON.parse(storage) : {}; 						
                return storageData[name];
            }else{
                return storage? JSON.parse(storage) : {};
            }   
        },

        removeStorage: function(key, name){    
            var returnValue;
            var data = {  'state' : 'remove', 'key' : key  }
            if(name){
                var storage = sessionStorage.getItem(key);
                var storageData = storage? JSON.parse(storage) : {};
                delete storageData[name];						
                sessionStorage.setItem(key, JSON.stringify(storageData)); 
                returnValue =  storageData;
                data = $.extend(data, { 'name' : name});
            }else{
                sessionStorage.removeItem(key);
                returnValue =  null;
            }
            
            $(window).trigger(jQuery.Event("changeStorageData", data));

            return returnValue;
        },

        //만료기간을 가진 스토리지 저장
        setExipreStorage: function(key, value, expireTime){
            var storage = sessionStorage.getItem(key);
            var storageData = storage? JSON.parse(storage) : {};        
            //Internet Explorer 불가
            //storageData = Object.assign(storageData, value);

            var cookieExpire = new Date();
            cookieExpire.setDate(cookieExpire.getDate() + days);
            var expireDateString = vcui.date.format(cookieExpire,'yyyyMMddhhmmss');

            value += ("&&&" + expireDateString);

            $.extend(storageData, value);
            sessionStorage.setItem(key, JSON.stringify(storageData));
            
            //$(window).trigger("changeStorageData");

            return storageData;
        },

        getExipreStorage: function(key){							
            var storage = sessionStorage.getItem(key); 
            if(storage){
                var i = JSON.parse(storage);
                
                var index = !i ? -1 : i.indexOf("&&&");
                if(index != -1) {
                    var checkExpire = new Date();

                    var arr = i.split('&&&');
                    if(arr.length > 1) {
                        var value = arr[0];
                        var date = arr[1];

                        var year = date.substring(0, 4);
                        var month = date.substring(4, 6);
                        var day = date.substring(6, 8);
                        var hour = date.substring(8, 10);
                        var minute = date.substring(10, 12);
                        var second = date.substring(12, 14);
                        var expire = new Date(year, month-1, day, hour, minute, second);
                        var res = vcui.date.compare(checkExpire,expire);
                        if(res != 1) {
                            //날짜 지남
                            self.removeStorage(key,null);
                            return null;
                        } else {
                            return value;
                        }
                     } else {
                         return i;
                     }
                }
            } else {
                return null;
            }
        },

        //lgkorUI.setCookie('','', false, 1);

        // BTOCSITE-19814 *.lge.co.kr 간의 cookie 공유
        // setCookie를 내부에서 호출하는 함수가 많아 전부 인자로 넣어주기 부담스러워
        // 각각의 사용하는 js 에서 쿠키설정 함수호출 전 지정 후 null 처리하는 방식으로 처리
        cookieDomain: null,

        //쿠키
        setCookie: function(cookieName, cookieValue, deleteCookie, expireDay) {
            var self = this;
            var cookieExpire = new Date();
            if(deleteCookie) {
                cookieExpire = new Date(1);
            } else {
                var days = expireDay? expireDay : 30*6;
                cookieExpire.setDate(cookieExpire.getDate() + days);
            }

            var cookiePath = null;
            var cookieDomain = self.cookieDomain;
            var cookieSecure = false;

            var cookieText = escape(cookieName) + '=' + escape(cookieValue);
            cookieText += (cookieExpire ? ('; EXPIRES='+cookieExpire.toUTCString()) : '');
            cookieText += '; PATH=/';/*(cookiePath ? ('; PATH='+cookiePath) : '')*/;
            cookieText += (cookieDomain ? ('; DOMAIN='+cookieDomain) : '');
            cookieText += (cookieSecure ? '; SECURE' : '');

            document.cookie = cookieText;
        },

        // 자정까지만 쿠키 설정
        setCookieToday: function(cookieName, cookieValue) {
            var self = this;
            var cookieExpire = new Date();

            cookieExpire.setDate(cookieExpire.getDate() + 1 );

            //쿠키 expire 날짜 세팅
            var expireDate =new Date(cookieExpire.getFullYear(),cookieExpire.getMonth(),cookieExpire.getDate());

            var cookieDomain = self.cookieDomain;
            var cookieSecure = false;

            var cookieText = escape(cookieName) + '=' + escape(cookieValue);
            cookieText += (cookieExpire ? ('; EXPIRES='+expireDate.toUTCString()) : '');
            cookieText += '; PATH=/';
            cookieText += (cookieDomain ? ('; DOMAIN='+cookieDomain) : '');
            cookieText += (cookieSecure ? '; SECURE' : '');

            document.cookie = cookieText;
        },

        getCookie: function(cookieName, getRealValue){
            var self = this;
            var cookieValue = null;
            if(document.cookie){
                var cookieKey = escape(cookieName) + "="; 
                var cookieArr = document.cookie.split(";");
                
                for(var i = 0; i < cookieArr.length; i++) {
                    if(cookieArr[i][0] === " ") {
                        cookieArr[i] = unescape(cookieArr[i].substring(1));
                    }
                    if(cookieArr[i].indexOf(cookieKey) === 0) {
                        cookieValue = unescape(cookieArr[i].slice(cookieKey.length, cookieArr[i].length));
                    }
                }
    /*
                var array = document.cookie.split((escape(cookieName)+'='));
                console.log(array);
                if(array.length >= 2){
                    var arraySub = array[1].split(';');
                    cookieValue = unescape(arraySub[0]);
                }
                */
            }

            var index = !cookieValue ? -1 : cookieValue.indexOf("&&&");
            if(index != -1) {
                //array
                var checkExpire = new Date();
                var itemArray = cookieValue.split('|');
                var valueArray = [];
                var resultArray = [];
                itemArray.forEach(function (i, index) {
                    var arr = i.split('&&&');
                    if(arr.length > 1) {
                        var value = arr[0];
                        var date = arr[1];

                        var year = date.substring(0, 4);
                        var month = date.substring(4, 6);
                        var day = date.substring(6, 8);
                        var hour = date.substring(8, 10);
                        var minute = date.substring(10, 12);
                        var second = date.substring(12, 14);
                        var expire = new Date(year, month-1, day, hour, minute, second);
                        var res = vcui.date.compare(checkExpire,expire);
                        if(res != 1) {
                            //날짜 지남
                            //console.log('날짜지남',value);
                        } else {
                            valueArray.push(value);
                            resultArray.push(i);
                        }
                    } else {
                        valueArray.push(value);
                        resultArray.push(i);
                    }
                });
                if(resultArray.length != itemArray.length) {
                    self.setCookie(cookieName, resultArray.join('|'));
                }
                cookieValue = (getRealValue) ? resultArray.join('|') : valueArray.join('|');
            }
            return cookieValue;
        },

        deleteCookie: function(cookieName){
            var self = this;
            var temp = self.getCookie(cookieName, true);
            if(temp){
                self.setCookie(cookieName,temp,true);
            }
        },

        addCookieArrayValue: function(cookieName, addData, maxLength, expireDay) {
            var self = this;
            var items = self.getCookie(cookieName, true); // 이미 저장된 값을 쿠키에서 가져오기
            var itemArray = [];
            if(items) {
                itemArray = items.split('|');
                //겹치는 값 제거
                var findIndex = -1;
                for(var n=0;n<itemArray.length;n++) {
                    var value = itemArray[n];
                    var arr = value.split('&&&');
                    if(arr.length > 1) {
                        value = arr[0];
                    }
                    if(value == addData) {
                        findIndex = n;
                        break;
                    }
                };
                if(findIndex != -1) {
                    itemArray.splice(findIndex, 1);
                }
            }

            //값 새로 저장
            var days = expireDay? expireDay : 30*6;
            var cookieExpire = new Date();
            cookieExpire.setDate(cookieExpire.getDate() + days);
            var expireDateString = vcui.date.format(cookieExpire,'yyyyMMddhhmmss');

            addData += ("&&&" + expireDateString);

            itemArray.unshift(addData);
            itemArray = itemArray.slice(0,maxLength);

            items = itemArray.join('|');
            self.setCookie(cookieName, items, false, expireDay);

            /*
            console.log('saved',itemArray.length);
            var test = self.getCookie(cookieName);
            var searchedList = test.split('|');
            console.log(searchedList);
            */
        },

        removeCookieArrayValue: function(cookieName, removeData) {
            var self = this;
            var items = self.getCookie(cookieName, true); // 이미 저장된 값을 쿠키에서 가져오기
            var itemArray = [];
            if(items) {
                itemArray = items.split('|');
                var findIndex = -1;
                for(var n=0;n<itemArray.length;n++) {
                    var value = itemArray[n];
                    var arr = value.split('&&&');
                    if(arr.length > 1) {
                        value = arr[0];
                    }
                    if(value == removeData) {
                        findIndex = n;
                        break;
                    }
                };
                if(findIndex != -1) {
                    itemArray.splice(findIndex, 1);
                    items = itemArray.join('|');
                    self.setCookie(cookieName, items);
                }
            }
        },

        loadKakaoSdkForShare: function(callback){
            var self = this;
            // BTOCSITE-13759 카카오웹 임베디드 regression test 진행 요청
            if(!self.kakaoSdkLoad) {
                var script = document.createElement('script');
                script.onload = function () {
                    self.kakaoSdkLoad = true;
                    if(callback != null){
                        callback();
                        return;
                    }
                    self.loadCommonShareUI();
                };
                script.onerror = function(e){ 
                    self.kakaoSdkLoad = false;
                    void 0;
                }
                script.src 			= '//t1.kakaocdn.net/kakao_js_sdk/2.6.0/kakao.min.js';
                script.integrity 	= 'sha384-6MFdIr0zOira1CHQkedUqJVql0YtcZA1P0nbPrQYJXVJZUkTk/oX4U9GhUIs3/z8';
                script.crossOrigin 	= 'anonymous';
                document.head.appendChild(script); 
            } 
        },

        loadCommonShareUI: function(){
            vcui.require([
                'helper/sharer'
            ], function (Sharer) {
                // 공유하기 헬퍼 빌드
                Sharer.init({
                    selector: '.sns-list > li >  a',
                    attr: 'data-link-name' // sns서비스명을 가져올 속성
                });
            });
        },

        requestAjaxData: function(url, data, callback, type, dataType, ignoreCommonSuccessCheck, timeout, ignoreCommonLoadingHide, failCallback) {
            var self = this;
            var dtype = dataType? dataType : "json";
            var timelimit = timeout ? timeout : 15000;
            $.ajax({
                type : type? type : "GET",
                url : url,
                dataType : dtype,
                data : data,
                timeout : timelimit
            }).done(function (result) {
                if(!ignoreCommonLoadingHide) lgkorUI.hideLoading();

                if(dtype != "json") {
                    if(callback && typeof callback === 'function') callback(result);
                    return;
                }

                if(result.ssoCheckUrl != undefined && result.ssoCheckUrl != null && result.ssoCheckUrl != ""){
                    location.reload();                
                    return;
                }
                
                if(dtype == 'json' && result.status != 'success'){
                    //alert(result.message ? result.message : '오류발생');
                    if(ignoreCommonSuccessCheck) {
                        var data = result.data;
                        if(data && !Array.isArray(data) && typeof data === 'object') {
                            if(!data.success && !(typeof(data.success) === "boolean")) {
                                result.data.success = "N";
                            }
                        } else {
                            //     if(result.message) {
                            //         lgkorUI.alert("", {
                            //             title: result.message
                            //         });
                            //         //result.message = null;
                            //     }
                            //result.data = {"success" : "N"};
                            if(data && !data.success && !(typeof(data.success) === "boolean")) {
                                result.data.success = "N";
                            }
                        }
                        if(callback && typeof callback === 'function') callback(result); 
                    } else {
                        var data = result.data;
                        if(data && data.alert && !vcui.isEmpty(data.alert)) {
                            lgkorUI.alert("", {
                                title: data.alert.title
                            });
                        } else {
                            if(result.message) {
                                lgkorUI.alert("", {
                                    title: result.message
                                });
                            }
                        }
                        if(failCallback && typeof failCallback === 'function') failCallback(result);
                    }
                    return;
                }

                if(ignoreCommonSuccessCheck) {
                    var data = result.data;
                    if(data && !Array.isArray(data) && typeof data === 'object') {
                        if(!data.success && !(typeof(data.success) === "boolean")) {
                            result.data.success = "Y";
                        }
                    }
                    if(callback && typeof callback === 'function') callback(result); 
                } else {
                    var data = result.data;
                    //success가 비어 있으면 성공(Y) 라 친다
                    if(data && !Array.isArray(data) && typeof data === 'object') {
                        if(!data.success && !(typeof(data.success) === "boolean")) {
                            result.data.success = "Y";
                        }
                    }
                    /*
                    if(!data.success && !(typeof(data.success) === "boolean")) {
                        data.success = "Y";
                    }
                    */
                    if(data && !self.stringToBool(data.success) && data.alert) {
                        //에러
                        if(data && data.alert && !vcui.isEmpty(data.alert)) {
                            self.commonAlertHandler(data.alert);
                        }/* else {
                            if(result.message) {
                                lgkorUI.alert("", {
                                    title: result.message
                                });
                            }
                        }*/
                    } else {
                        if(callback && typeof callback === 'function') callback(result);
                    } 
                }                
            }).fail(function(err){
                //alert(url, err.message);
                if(!ignoreCommonLoadingHide) lgkorUI.hideLoading();
                if(failCallback && typeof failCallback === 'function') failCallback(err);

            });
        },

        requestAjaxDataFailCheck: function(url, data, successCallback, failCallback) {
            var self = this;
            self.requestAjaxData(url, data, successCallback, null, null, null, null, null, failCallback);
        },
        
        requestAjaxDataIgnoreCommonSuccessCheck: function(url, data, callback, type, dataType) {
            var self = this;
            self.requestAjaxData(url, data, callback, type, dataType, true);
        },

        requestAjaxDataAddTimeout: function(url, timeout, data, callback, type, dataType, ignoreCommonSuccessCheck){
            var self = this;
            self.requestAjaxData(url, data, callback, type, dataType, ignoreCommonSuccessCheck, timeout);
        },

        requestAjaxDataPost: function(url, data, callback, ignoreCommonSuccessCheck, ignoreCommonLoadingHide, failCallback) {
            var self = this;
            self.requestAjaxData(url, data, callback, "POST", null, ignoreCommonSuccessCheck, null, ignoreCommonLoadingHide, failCallback);
        },

        requestAjaxFileData: function(url, data, callback, type, dataType, ignoreCommonSuccessCheck, failcallback) {
            //BTOCSITE-6032 : failcallback  추가
            var self = this;
            var dtype = dataType? dataType : "json";
            $.ajax({
                type : type? type : "GET",
                url : url,
                dataType : dtype,
                data : data,
                enctype: 'multipart/form-data',
                processData: false,
                contentType: false
            }).done(function (result) {
                
                if(result.ssoCheckUrl != undefined && result.ssoCheckUrl != null && result.ssoCheckUrl != ""){
                    location.reload();                
                    return;
                }
                
                if(dtype == 'json' && result.status != 'success'){
                    //alert(result.message ? result.message : '오류발생');
                    void 0;
                    if(callback && typeof callback === 'function') callback(result); 
                    return;
                }

                if(ignoreCommonSuccessCheck) {
                    if(callback && typeof callback === 'function') callback(result); 
                } else {
                    var data = result.data;
                    //success가 비어 있으면 성공(Y) 라 친다
                    if(!data.success && !(typeof(data.success) === "boolean")) {
                        data.success = "Y";
                    }
                    if(data && !self.stringToBool(data.success) && data.alert) {
                        //에러
                        self.commonAlertHandler(data.alert);
                    } else {
                        if(callback && typeof callback === 'function') callback(result);
                    } 
                }   
            }).fail(function(err){
                //alert(url, err.message);
                void 0;
                //BTOCSITE-6032 failcallback 추가
                if( failcallback) {
                    failcallback();
                }
            });
        },

        requestCartCount: function(ajaxUrl) {
            var cartCnt = 0;
            var obsCnt = 0;
            var careCnt = 0;
            var cartUrl = "";
            
            // B2B 여부
            var isB2b = location.href.indexOf("/business/") > -1 || location.pathname == "/business" 
                || 'Y' == new URL(location.href).searchParams.get('b2bYn') ;
            isB2b = location.pathname == '/my-page' ? false : isB2b;
            var obsCartUrl = isB2b ? "/business/shop/commonModule/miniCart.lgajax" : "/shop/commonModule/miniCart.lgajax";
            
            // BTOCSITE-56647 CARTID 쿠키명 로직 보완
            var lastSubDomain = window.location.hostname.split(".")[0].toLowerCase();

            var cartIdCookieName = "OBS_CARTID";
            if (lastSubDomain.indexOf("stg") !== -1) {
                cartIdCookieName = "OBS_CARTID_STG";
            } else if (lastSubDomain.indexOf("dev") !== -1 || lastSubDomain.indexOf("test") !== -1 || lastSubDomain === "localhost") {
                cartIdCookieName = "OBS_CARTID_DEV";
            }
                    
            lgkorUI.requestAjaxDataPost(ajaxUrl, null, function(result){
                var data = result.data;
                if(lgkorUI.stringToBool(data.success)) {
                    careCnt = data.cartCnt ? ((typeof data.cartCnt  === 'number') ? data.cartCnt : parseInt(data.cartCnt)) : 0;
                    var utility = $('div.header-wrap div.utility');
                    utility.find('.cart span.count').remove();
                    cartUrl = isB2b ? "/business/shop/cart/index" : data.cartUrl;   // B2B 장바구니 분기

                    // BTOCSITE-35258 cookie에 cart Id 미 생성시 빠져나감
                    if(vcui.isEmpty(lgkorUI.getCookie(cartIdCookieName))){
                        return;           
                    } 
                    
                    // OBS API 호출
                    lgkorUI.requestAjaxDataPost(obsCartUrl, null, function (result) {
                        var obsData = result.data;
                        if (lgkorUI.stringToBool(obsData.success)) {
                            obsCnt = typeof obsData.obsCartTotalCnt === 'number' ? obsData.obsCartTotalCnt : parseInt(obsData.obsCartTotalCnt);
                        }

                        // B2B 는 careCnt 계산하지 않음.
                        cartCnt = isB2b ? obsCnt : obsCnt + careCnt;

                        if (cartCnt == 0) {
                            //제거
                        } else if (cartCnt > 99) {
                            //99개 넘음
                            utility.find('.cart').append('<span class="count"><span class="blind">장바구니 제품 수</span>99+</span>');
                        } else {
                            //NN
                            utility.find('.cart').append('<span class="count"><span class="blind">장바구니 제품 수</span>' + cartCnt + '</span>');
                        }

                        if (!isB2b && careCnt > 0 && obsCnt == 0) {
                            cartUrl = "/add-to-cart/rental-care-solution";
                        }

                        $('.header-wrap .header-top .utility .cart a').attr('href', cartUrl);
                    }, true, true);
                }
            }, true, true);
        },

        requestCart: function(ajaxUrl, param, isToast, isReturn) {
            lgkorUI.showLoading();
            isToast = !(isToast) ? true : isToast;

            var cartCnt = 0;
            var obsCnt = 0;
            var careCnt = 0;
            var cartUrl = "";
            var obsCartUrl = lgkorUI.DEFAULT_URL+"/shop/commonModule/miniCart.lgajax";  // TODO : template html 로 이동

            // B2B 여부
            var isB2b = location.href.indexOf("/business/") > -1 || location.pathname == "/business"
                || 'Y' == new URL(location.href).searchParams.get('b2bYn') ;
            isB2b = location.pathname == '/my-page' ? false : isB2b;

            lgkorUI.requestAjaxDataPost(ajaxUrl, param, function(result){
                var data = result.data;
                var typeFlag = self.$('.btn-cart').attr('data-type-flag'); //(PLP) BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선
                var pdpInfoFlag = self.$('.pdp-info-area').attr('data-type-cart'); //(PDP) BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선
                var wishTypeFlag = self.$('.info-tbl-wrap ul').attr('data-type-cart'); //(찜/최근) BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선
                var compareFlag = self.$('.product-items').attr('data-type-cart'); //(비교하기) BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선
                var disposableCartUrl = ""; // (소모품PDP) BTOCSITE-69808 장바구니 탭 선택
                try {
                    disposableCartUrl = self.$('.pdp-info-area').attr('data-disposable-cart-url');
                } catch (e) {
                    disposableCartUrl = "";
                }

                if(data && lgkorUI.stringToBool(data.success)) {
                    //var cartCnt = data.cartCnt ? ((typeof data.cartCnt  === 'number') ? data.cartCnt : parseInt(data.cartCnt)) : 0;
                    careCnt = data.cartCnt ? ((typeof data.cartCnt  === 'number') ? data.cartCnt : parseInt(data.cartCnt)) : 0;

                    var utility = $('div.header-wrap div.utility');
                    utility.find('.cart span.count').remove();

                    cartUrl = data.cartUrl;

                    /* BTOCSITE-33438 [상품] CLONE - 임직원몰 관련 GNB mini cart 수량 API 수정 20230315
                     if(cartCnt == 0) {
                        //제거
                    } else if(cartCnt > 99) {
                        //99개 넘음
                        utility.find('.cart').append('<span class="count"><span class="blind">장바구니 제품 수</span>99+</span>');
                    } else {
                        //NN
                        utility.find('.cart').append('<span class="count"><span class="blind">장바구니 제품 수</span>' + cartCnt + '</span>');
                    }

                    $('.header-wrap .header-top .utility .cart a').attr('href', data.cartUrl);
                    */

                    // OBS API 호출
                    lgkorUI.requestAjaxDataPost(obsCartUrl, null, function(result){
                        var obsData = result.data;

                        if(lgkorUI.stringToBool(obsData.success)) {
                            obsCnt = typeof obsData.obsCartTotalCnt  === 'number' ? obsData.obsCartTotalCnt : parseInt(obsData.obsCartTotalCnt);
                        }

                        // B2B 는 careCnt 계산하지 않음.
                        cartCnt = isB2b ? obsCnt : obsCnt + careCnt;
                        
                        if(cartCnt == 0) {
                            //제거
                        } else if(cartCnt > 99) {
                            //99개 넘음
                            utility.find('.cart').append('<span class="count"><span class="blind">장바구니 제품 수</span>99+</span>');
                        } else {
                            //NN
                            utility.find('.cart').append('<span class="count"><span class="blind">장바구니 제품 수</span>' + cartCnt + '</span>');
                        }

                        if(careCnt > 0 && obsCnt == 0){
                            cartUrl = "/add-to-cart/rental-care-solution";
                        }

                        if(vcui.isEmpty(lgkorUI.DEFAULT_URL)){
                            $('.header-wrap .header-top .utility .cart a').attr('href', cartUrl);
                        }
                    }, true, true);


                    if(isReturn && isToast && lgkorUI.stringToBool(data.success)) {
                        $(window).trigger("addCartReturn");
                    } else if(isToast && lgkorUI.stringToBool(data.success)) {
                        $(window).trigger("toastshow", "선택하신 제품을 장바구니에 담았습니다.");
                        /* BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선 */
                        if( typeFlag == "CARESOLUTION" || pdpInfoFlag == "C" || wishTypeFlag == "C" || compareFlag == "C" ){
                            // BTOCSITE-48266 : B2B OBS 구축 URL 추가
                            var url = lgkorUI.DEFAULT_URL+"/add-to-cart/rental-care-solution";
                            $('.toast-text').append('<br><a href="'+url+'" class="btn-link white">장바구니 이동하기</a>');
                        }else if(disposableCartUrl == '/shop/cart/index'){
                            var url = lgkorUI.DEFAULT_URL+disposableCartUrl;
                            try {
                                var salesType = $("#employeeDisposableTabArea").find("li.on").attr("data-salestype");
                                if(!$("#employeeDisposableTab").is(":visible")){
                                    salesType = "B";
                                }
                                $('.toast-text').append('<br><a href="' + url + '?salesType=' + salesType + '&firstYn=N" class="btn-link white">장바구니 이동하기</a>');
                            } catch (e) {
                                $('.toast-text').append('<br><a href="' + url + '?salesType=B&firstYn=N" class="btn-link white">장바구니 이동하기</a>');
                            }
                        }else{
                            // BTOCSITE-48266 : B2B OBS 구축 URL 추가
                            var url = lgkorUI.DEFAULT_URL+"/shop/lgobscart/cart/quote";
                            $('.toast-text').append('<br><a href="'+url+'" class="btn-link white">장바구니 이동하기</a>');
                        }
                        /* //BTOCSITE-10576 [사용자행태분석 개선사항] 장바구니 이동 경로 제공 / 품절 관련 무효클릭 및 안내 개선 */
                    }
                } else {
                    if(data && data.alert && !vcui.isEmpty(data.alert)) {
                        if(isToast) {
                            $(window).trigger("toastshow",data.alert.title);
                        } else {
                            lgkorUI.alert("", {
                                title: data.alert.title
                            });
                        }
                    } else {
                        if(result.message) {
                            lgkorUI.alert("", {
                                title: result.message,
                                ok: function() {
                                    if (result.code) {
                                        if (result.code == 'OUT_OF_STOCK' && param.bizType && param.bizType == 'DISPOSABLE') {
                                            var newUrl = window.location.href + '?refresh=true';
                                            window.location.href = newUrl;
                                        }
                                    }else if (result.errType && result.errType === '01'){
                                        window.location.href = '/sso/member/logout?state=' + location.origin;
                                    }
                                }
                            });
                        }
                    }
                }
            }, true);

            this.setRecentViewProduct(param.id);
        },

        requestWish: function(param, wish, callbackSuccess, callbackFail, postUrl, opener) {
            void 0
            lgkorUI.showLoading();
            var self = this;
            param.wish = wish;
            lgkorUI.requestAjaxDataPost(postUrl, param, function(result){
                var data = result.data;
                if(data && lgkorUI.stringToBool(data.success)) {
                    if(wish) {
                        $(window).trigger("toastshow","선택하신 제품이 위시리스트에 추가되었습니다."); /* BTOCSITE-17584 찜하기 MKT 전환으로 인한 기획전/이벤트 영향범위 수정 */
                    } else{
                        $(window).trigger("toastshow","위시리스트 제품 설정이 해제되었습니다."); /* BTOCSITE-17584 찜하기 MKT 전환으로 인한 기획전/이벤트 영향범위 수정 ,BTOCSITE-26649 텍스트 */
                    }
                    if(data.data && data.data.listData && data.data.listData.length > 0) {
                        callbackSuccess(data.data.listData[0]);
                    } else {
                        callbackSuccess({wishItemId:null});
                    }
                } else {
                    callbackFail(data);
                    if(data && data.alert && !vcui.isEmpty(data.alert)) {
                        if(data.alert.isConfirm && data.alert.okUrl) {
                            data.alert.okUrl = data.alert.okUrl + location.href;
                        }
                        self.commonAlertHandler(data.alert, opener);
                        /*
                        lgkorUI.alert("", {
                            title: data.alert.title
                        });
                        */
                    } else {
                        if(result.message) {
                            lgkorUI.alert("", {
                                title: result.message
                            }, opener);
                        }
                    }
                }
            }, true);
        },

        //[BTOCSITE-57367][전시]리뷰 내재화 > 보유제품 카운트 수
        requestPossesionProductCount: function() {
            // B2B 여부
            var isB2b = location.href.indexOf("/business/") > -1 || location.pathname == "/business"
                || 'Y' == new URL(location.href).searchParams.get('b2bYn') ;
            isB2b = location.pathname == '/my-page' ? false : isB2b;

            var mypageMenu;
            if(!isB2b) {
                mypageMenu = $('div.mypage-layer.after-login').find('.mypage-shortcut').find("a[href*='/my-page/manage-products']");
            }else {
                mypageMenu = $('li.mypage.after-login').find('.mypage-shortcut').find("a[href*='/my-page/manage-products']");
            }
            //로그인 여부
            var loginFlag = digitalData.hasOwnProperty("userInfo") && digitalData.userInfo.unifyId ? "Y" : "N";
            if(loginFlag === 'Y'){
                if(mypageMenu.length > 0){
                    //카운트 영역 초기화
                    mypageMenu.find('em.count').remove();
                    //ajax 호출
                    var param = {'unifyId' : digitalData.userInfo.unifyId };
                    lgkorUI.requestAjaxData("/mkt/api/retrieveMyPossesionCount.lgajax", param, function(result) {
                        var data = result.data[0];
                        var totalCount = data.totalCount ? data.totalCount : 0;
                        //메뉴에 카운트 추가
                        if(parseInt(totalCount) > 0){
                            if(parseInt(totalCount) > 99){
                                mypageMenu.append('<em class="count"><span class="blind">보유제품 수</span>99+</em>');
                            }else{
                                mypageMenu.append('<em class="count"><span class="blind">보유제품 수</span>'+ totalCount +'</em>');
                            }
                        }
                    }, "POST", "json");
                }
            }
        },

        //[BTOCSITE-57367][전시]리뷰 내재화 > 작성가능 리뷰 카운트 수
        requestMyReviewCount: function(ajaxUrl) {
            // B2B 여부
            var isB2b = location.href.indexOf("/business/") > -1 || location.pathname == "/business"
                || 'Y' == new URL(location.href).searchParams.get('b2bYn') ;
            isB2b = location.pathname == '/my-page' ? false : isB2b;

            var mypageMenu;
            if(!isB2b) {
                mypageMenu = $('div.mypage-layer.after-login').find(".mypage-shortcut").find("a[href*='/my-page/product-reviews']");
            }else {
                mypageMenu = $('li.mypage.after-login').find(".mypage-shortcut").find("a[href*='/my-page/product-reviews']");
            }
            //넘어온 url이 없을 경우
            if(ajaxUrl == undefined || ajaxUrl == null || ajaxUrl == ''){
                ajaxUrl = '/my-page/review/api/reviewCounting';
            }
            //로그인 여부
            var loginFlag = digitalData.hasOwnProperty("userInfo") && digitalData.userInfo.unifyId ? "Y" : "N";
            if(loginFlag === 'Y'){
                if(mypageMenu.length > 0) {
                    //카운트 영역 초기화
                    mypageMenu.find('em.count').remove();
                    //ajax 호출
                    var param = JSON.stringify({"unifyId": digitalData.userInfo.unifyId});
                    $.ajax({
                        type: "POST",
                        url: ajaxUrl,
                        data: param,
                        contentType: "application/json",
                        success: function (result) {
                            var data = result.data;
                            if(result.status === "success"){
                                var reviewCount = data.myCanWriteCount;
                                //메뉴에 카운트 추가
                                if(parseInt(reviewCount) > 0){
                                    if(parseInt(reviewCount) > 99){
                                        mypageMenu.append('<em class="count"><span class="blind">상품 리뷰 수</span>99+</em>');
                                    }else{
                                        mypageMenu.append('<em class="count"><span class="blind">상품 리뷰 수</span>'+ reviewCount +'</em>');
                                    }
                                }
                            }
                        },
                        error: function (error) {
                            void 0;
                        },
                    });
                }
            }
        },

        commonAlertHandler: function(alert, opener){
            if(!alert) {
                return;
            }
            var isConfirm = lgkorUI.stringToBool(alert.isConfirm);
            if(isConfirm) {
                //컨펌
                var obj ={title: alert.title,
                    typeClass: '',
                    cancelBtnName: alert.cancelBtnName ? alert.cancelBtnName : "취소",
                    okBtnName: alert.okBtnName ? alert.okBtnName : "확인",
                    ok: alert.okUrl ? function (){
                        location.href = alert.okUrl;
                    } : function (){},
                    cancel: alert.cancelUrl ? function (){
                        location.href = alert.cancelUrl;
                    } : function (){}
                };
    
                var desc = alert.desc ? alert.desc : null;
                if(alert.title && alert.desc) {
                    obj.typeClass = 'type2'
                }
                lgkorUI.confirm(desc, obj, opener);
            } else {
                //알림
                var obj ={title: alert.title,
                    typeClass: '',
                    okBtnName: alert.okBtnName ? alert.okBtnName : "확인",
                    ok: function (){}
                };
    
                var desc = alert.desc ? alert.desc : null;
                if(desc) {
                    obj.typeClass = 'type2'
                }
                lgkorUI.alert(desc, obj, opener);
            }
        },

        isString: function(value) {
            return typeof value === 'string' || value instanceof String;
        },

        stringToBool: function(str, returnNull) {
            if(!str) {
                return !(!returnNull);
            }
            if(typeof str === 'boolean') {
                return str;
            }
            if(str && (typeof str === 'string' || str instanceof String)) {
                var s = str.trim().toLowerCase();
                return (s == 'y' || s == 'yes' || s == 'true');
            }
            return str;
        },

        getHiddenInputData: function(iptname, wrapname, index){
            var hiddenWrapName = wrapname || "hidden-input-group";
            var hiddenIndex = index || "0";
            var hiddenWrap = $('.' + hiddenWrapName).eq(hiddenIndex);
            var data, str, name;

            if(iptname){
                if(vcui.isArray(iptname)){
                    data = {};
                    for(str in iptname){
                        name = iptname[str];
                        data[name] = hiddenWrap.find('input[name=' + name + ']').val();
                    }

                    return data;
                } else{
                    return hiddenWrap.find('input[name=' + iptname + ']').val();
                }
            } else{
                data = {};
                hiddenWrap.find('input[type=hidden]').each(function(idx, item){
                    name = $(item).attr('name');
                    data[name] = $(item).val();
                });

                return data;
            }
        },

        setHiddenInputData: function(iptname, value, wrapname, index){
            if(!iptname) return false;

            var hiddenWrapName = wrapname || "hidden-input-group";
            var hiddenIndex = index || "0";
            var hiddenWrap = $('.' + hiddenWrapName).eq(hiddenIndex);
            var str, val;

            if(typeof iptname === "object"){
                for(str in iptname){
                    hiddenWrap.find('input[name=' + str + ']').val(iptname[str]);
                }
            } else{
                val = vcui.isArray(value) ? value.join() : value;
                hiddenWrap.find('input[name=' + iptname + ']').val(val);
            }
        },

        addStickyModule: function(hei){
            var self = this;

            var moduleIDs = vcui.array.map(self.STICKY_MODULES, function(item){
                return item.uniqueID;
            });

            var uniqueID = self.setUniqueID();
            while(vcui.array.has(moduleIDs, uniqueID)) uniqueID = setUniqueID();

            self.STICKY_MODULES.push({
                uniqueID: uniqueID,
                moduleHeight: hei
            });

            return uniqueID;
        },

        setUniqueID: function(){
            var id = "";
            for(var i=0;i<10;i++){
                var ran = parseInt(Math.random()*10);
                id += ran.toString();
            }

            return id;
        },

        searchModelName: function(){
            var optionsTmpl =  '<option value="{{code}}">{{name}}</options>';

            var modelName = {
                options: {
                    text: '제품 카테고리를 선택하면, 해당 제품의 모델명 확인 방법을 안내해 드립니다.',
                    imgPath: '/kr/support/images/find-model/model-multi-air.jpg', //210819 BTOCSITE-4574 이미지 변경 3in1
                    imgAlt: '모델명 및 제조번호 확인 예시 사진'
                },
                init: function() {
                    var self = this;

                    self.$modelButton = $('[data-href="#modelNamePopup"]');
                    self.$modelPopup = $('#modelNamePopup');
                    self.$category = self.$modelPopup.find('#select1');
                    self.$subCategory = self.$modelPopup.find('#select2');
                    self.$result = self.$modelPopup.find('.example-result');

                    self.searchModelNameUrl = self.$modelPopup.data('modelUrl');
                    self.searchSubCategoryUrl = self.$modelPopup.data('subCategoryUrl');

                    self.bindEvent();
                },
                setting: function() {
                    var self = this;
                    
                    self.category = self.$modelPopup.data('category');
                    self.subCategory = self.$modelPopup.data('subCategory');
                    
                    if (self.category) {
                        self.$category.find('option[value="'+self.category+'"]').prop('selected', true);
                        self.$category.vcSelectbox('update');
                        self.searchSubCategory(self.category, self.subCategory);
                        if (self.subCategory) {
                            self.searchModelName(self.category, self.subCategory);
                        }
                    } else {
                        var $resultTxt = self.$result.find('.txt'),
                            $resultImg = self.$result.find('.img img'),
                            $viewBtn = self.$result.find('.img .btn-img-view');
                        var options = self.options;

                        $resultTxt.html(options.text);
                        $resultImg.attr('src', options.imgPath);
                        $resultImg.attr('alt', options.imgAlt);
                        $viewBtn.attr('href', options.imgPath);

                        self.$category.find('option.placeholder').prop('selected', true);
                        self.$subCategory.prop('disabled', true);
                        self.$subCategory.find('option:not(.placeholder)').remove();

                        self.$category.vcSelectbox('update');
                        self.$subCategory.vcSelectbox('update');
                    }
                },
                searchSubCategory: function(category, subCategory) {
                    var self = this;
                    var subCategory = subCategory || '';
                    var param = {
                        cateSelect: category || self.$category.val()
                    };
                    
                    lgkorUI.showLoading();
                    lgkorUI.requestAjaxData(self.searchSubCategoryUrl, param, function(result) {
                        var data = result.data;
                        var html = '';

                        data.forEach(function(item) {
                            html += vcui.template(optionsTmpl, item);
                        });

                        self.$subCategory.find('option:not(.placeholder)').remove();
                        self.$subCategory.append(html).prop('disabled', false);
                        self.$subCategory.find('option[value="'+subCategory+'"]').prop('selected', true);
                        self.$subCategory.vcSelectbox('update');
                    }, 'POST');
                },
                searchModelName: function(category, subCategory) {
                    var self = this;
                    var param = {
                        category: category || self.$category.val(),
                        subCategory: subCategory || self.$subCategory.val()
                    };
                    
                    lgkorUI.showLoading();
                    lgkorUI.requestAjaxData(self.searchModelNameUrl, param, function(result) {
                        var $resultTxt = self.$result.find('.txt'),
                            $resultImg = self.$result.find('.img img'),
                            $viewBtn = self.$result.find('.img .btn-img-view');
                        var data = result.data;
                        
                        $resultTxt.html(data.text);
                        $resultImg.attr('src', data.imgPath);
                        $resultImg.attr('alt', data.imgAlt);
                        $viewBtn.attr('href', data.imgPath);
                    }, 'POST');
                },
                bindEvent: function() {
                    var self = this;

                    self.$modelButton.on('click', function() {
                        self.setting();
                        self.$modelPopup.vcModal();
                    });

                    self.$category.on('change', function() {
                        self.searchSubCategory();
                    });

                    self.$subCategory.on('change', function() {
                        self.searchModelName();
                    });

                    self.$modelPopup.on('modalhidden', function() {
                        self.$modelButton.focus();
                    });
                }
            }

            modelName.init();
        },

        addLimitedInputEvent: function(ipt){
            ipt.on('input', function(){
                var maxleng = $(this).prop('maxlength');
                var str = $(this).val();
                if(str.length > maxleng){
                    $(this).val(str.slice(0, maxleng));
                }
            });
        },

        parseUrl: function (url) {
            var protocol = url.split('//')[0],
                comps = url.split('#')[0].replace(/^(https\:\/\/|http\:\/\/)|(\/)$/g, '').split('/'),
                host = comps[0],
                search = comps[comps.length - 1].split('?')[1],
                tmp = host.split(':'),
                port = tmp[1],
                hostname = tmp[0];

            search = typeof search !== 'undefined' ? '?' + search : '';

            var params = search
            .slice(1)
            .split('&')
            .map(function (p) { return p.split('='); })
            .reduce(function (obj, pair) {
                    pair[0] = pair[0] || '';
                    pair[1] = pair[1] || '';  

                    if(pair[0]) {
                        var parts = pair.map(function(param) {
                            return decodeURIComponent(param);
                        });
                        obj[parts[0]] = parts[1];
                    }
                return obj
            }, {});

            return {
                hash: url.indexOf('#') > -1 ? url.substring(url.indexOf('#')) : '',
                protocol: protocol,
                host: host,
                hostname: hostname,
                href: url,
                pathname: '/' + comps.splice(1).map(function (o) { return /\?/.test(o) ? o.split('?')[0] : o; }).join('/'),
                search: search,
                origin: protocol + '//' + host,
                port: typeof port !== 'undefined' ? port : '',
                searchParams: {
                    get: function(p) {
                        return p in params? params[p] : ''
                    },
                    getAll: function(){ return params; }
                }
            };
        },


        getParameterByName: function(name) {
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
                results = regex.exec(location.search);
            return results == null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
        },

        //현재 페이지의 찜하기 객체를 찾아서 갱신한다
        //LGECOMVIO-20 찜하기 data 데이터 변경되어 수정
        checkWishItem: function(ajaxUrl,checkAttr) {

            if(!checkAttr) {
                checkAttr 	  = "data-wish-model-name";
                checkAttrCate = "data-wish-category-id";
            }
            var $wishItem = $('input['+checkAttr+']');

            $('input['+checkAttr+']').prop("checked", false);   // BTOCSITE-32524 :  위사체크를 모두 해제하여 초기화(위시 항목은 아래에서 체크처리)

            if($wishItem.length > 0) {
                lgkorUI.requestAjaxData(ajaxUrl, {"type":"list"}, function(result){
                    var data = result.data.data;
                    if(data) {
                        var listData = data.listData != undefined ? data.listData : [];
                        $wishItem.each(function(idx, item){
                            var $item = $(item);
                            var itemId = $item.attr(checkAttr);
                            var itemCategory;
                            
                            if( "PDP" === lgkorUI.getHiddenInputData().pageGupun ) {
                            	itemCategory = lgkorUI.getHiddenInputData().categoryId;
                            } else {
                            	itemCategory = $item.attr(checkAttrCate);
                            }
                            
                            for (var i = 0, len = listData.length; i < len; i++) {
                                var listItem = listData[i];
                                if (listItem.sku == itemId && listItem.categoryId == itemCategory) {
                                	$item.data(listItem);
                                    $item.prop("checked",true);
                                    break;
                                }
                            }
                        });
                    }
                },"GET", null, true);
            }
        },

        stringCompress:function(str, asArray) {
            asArray = (asArray === true);
            var i,
                dictionary = {},
                uncompressed = str,
                c,
                wc,
                w = "",
                result = [],
                ASCII = '',
                dictSize = 256;
            for (i = 0; i < 256; i += 1) {
                dictionary[String.fromCharCode(i)] = i;
            }
        
            for (i = 0; i < uncompressed.length; i += 1) {
                c = uncompressed.charAt(i);
                wc = w + c;
                //Do not use dictionary[wc] because javascript arrays
                //will return values for array['pop'], array['push'] etc
               // if (dictionary[wc]) {
                if (dictionary.hasOwnProperty(wc)) {
                    w = wc;
                } else {
                    result.push(dictionary[w]);
                    ASCII += String.fromCharCode(dictionary[w]);
                    // Add wc to the dictionary.
                    dictionary[wc] = dictSize++;
                    w = String(c);
                }
            }
        
            // Output the code for w.
            if (w !== "") {
                result.push(dictionary[w]);
                ASCII += String.fromCharCode(dictionary[w]);
            }
            return asArray ? result : ASCII;
        },

        stringDecompress:function (str) {
            var i, tmp = [],
                dictionary = [],
                compressed = str,
                w,
                result,
                k,
                entry = "",
                dictSize = 256;
            for (i = 0; i < 256; i += 1) {
                dictionary[i] = String.fromCharCode(i);
            }
        
            if(compressed && typeof compressed === 'string') {
                // convert string into Array.
                for(i = 0; i < compressed.length; i += 1) {
                    tmp.push(compressed[i].charCodeAt(0));
                }
                compressed = tmp;
                tmp = null;
            }
        
            w = String.fromCharCode(compressed[0]);
            result = w;
            for (i = 1; i < compressed.length; i += 1) {
                k = compressed[i];
                if (dictionary[k]) {
                    entry = dictionary[k];
                } else {
                    if (k === dictSize) {
                        entry = w + w.charAt(0);
                    } else {
                        return null;
                    }
                }
        
                result += entry;
        
                // Add w+entry[0] to the dictionary.
                dictionary[dictSize++] = w + entry.charAt(0);
        
                w = entry;
            }
            return result;
        },

        obj2HashString:function(obj) {
            var compress = lgkorUI.stringCompress(JSON.stringify(obj));
            //console.log(compress);
            var result = compress.substring(1, compress.length-1);
            //var result = compress;
            return result;
        },

        hashString2Obj:function(str) {
            var orig =  decodeURIComponent("{"+str+"}"); //"{"+str+"}";
            var decompress = lgkorUI.stringDecompress(orig);
            var result = JSON.parse(decompress);
            return result;
        },

        //크레마로그인
        cremaLogin:function() {

            // 크레마 init 구조상 cremaAsyncInit 함수가 먼저 선언되 있어야 초기화 오류가 안난다.
            window.cremaAsyncInit = function () {
                if(typeof crema !== 'undefined') {
                    lgkorUI.requestAjaxDataPost('/mkt/commonModule/cremaInfo.lgajax', null, function(result){
                        if(result.status === 'success' && result.data) {
                            var cremaid   = result.data[0].data.cremaId || '';
                            var cremaname = result.data[0].data.cremaName || '';
                            crema.init(cremaid , cremaname);
                        }
                    }) 
                }
            };

            (function(i,s,o,g,r,a,m){
                var isMobile = false;
                if(vcui.detect.isMobile){
                    isMobile = true;
                }
                
                if(location.hostname == "www.lge.co.kr") {
                    r = isMobile ? "//widgets.cre.ma/lge.co.kr/mobile/init.js" : "//widgets.cre.ma/lge.co.kr/init.js";
                } else {
                    r = isMobile ? "//widgets.cre.ma/lge.co.kr/mobile/init.js" : "//widgets.cre.ma/lge.co.kr/init.js";
                }
            
                if(s.getElementById(g)){
                    return
                };
                a=s.createElement(o),m=s.getElementsByTagName(o)[0];
                a.id=g;
                a.async=1;
                a.src=r;
                m.parentNode.insertBefore(a,m);
            })(window,document,'script','crema-jssdk','//widgets.cre.ma/lge.co.kr/init.js');


            window.cremaAsyncInit();

        },

        //크레마리플래쉬
        cremaReload:function() {
            if(typeof crema !== 'undefined' && typeof crema == "object" && typeof crema.run == 'function') {
                setTimeout(function(){
                    crema.run();
                },500);
            }
        },

        // 앱 하단 메뉴 컨트롤 부분

        // 앱 하단메뉴가 화면을 덮는 형태인지 아닌지 결정
        showAppBottomMenuOver:function(flag){
            if(isApplication) {
                if(isAndroid && android) {
                    android.showBottomMenuOver(flag);
                }
                if(isIOS){
                    var jsonString= JSON.stringify({command:'showBottomMenuOver', value:flag? "Y":"N"});
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                }
            }
        },
            // 모바일웹,앱 하단메뉴 노출 여부 설정
            showBottomMeun:function(flag){
                //모바일웹
                var $bar = $('.mobile-status-bar');
                if(window.innerWidth < 768 && !isApplication && $bar.length != 0 ){ 
                    if(flag){
                        if(this.isLayerPopup){
                            $bar.show();
                        }
                        this.isLayerPopup = false;
                    }else{
                        if ( $bar.css('display') == 'block' ){
                            this.isLayerPopup = true;
                            $bar.hide();
                        }
                    }
                }
                //앱
                this.appIsLayerPopup(!flag);
            },
        // 앱 하단메뉴 스크롤 기능 사용 여부 설정
        setEnableAppScrollBottomMenu:function(flag){
            if(isApplication) {
                if(isAndroid && android) {
                    android.setEnableScrollBottomMenu(flag);
                }
                if(isIOS){
                    var jsonString= JSON.stringify({command:"setEnableScrollBottomMenu", value : flag? "Y" : "N"});
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                }
            }
        },
        // 앱 하단메뉴 노출 여부 설정
        showAppBottomMenu:function(flag){

            if(isApplication) {
                if(isAndroid && android) android.showBottomMenu(flag);
                if(isIOS) {
                    var jsonString= JSON.stringify({command:'showBottomMenu', value:flag? "Y" : 'N'});
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                }
            }
        },
        // 앱 하단메뉴 타입 설정 type - 0(default) : 기본 B2C 하단 탭바, 1 : 사업자몰 b2b 하단 탭바
        setBottomMenuType:function(type) {

            if(isApplication) {
                if(isAndroid && android) android.setBottomMenuType(type);
                if(isIOS) {
                    var jsonString= JSON.stringify({command:'setBottomMenuType', type:type});
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                }
            }
        },

        // HelloBar(GNB) 영역 노출 여부
        showHelloBar:function(flag) {
            try{
                if(flag) {
                    if( $('.header').hasClass('helloBar') == false ) {
                        $('.header').addClass('helloBar');
                    }
                }
            } catch (e) {}
        },

        // 앱 isLayoutPopup
        appIsLayerPopup:function(flag){

            if(isApplication) {
                if(isAndroid && android) android.isLayerPopup(flag);
                if(isIOS) {
                    var jsonString= JSON.stringify({command:'isLayerPopup', value:flag? "Y" : 'N'});
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                }
            }
        },

        // openAR 링크 보내기
        openAR:function(modelId){
            if(isApplication) {
                if(modelId){
                    if(isAndroid && android) android.openAR(modelId);
                    if(isIOS) {
                        var jsonString= JSON.stringify({command:'showAR', product:modelId});
                        webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                    }
                }
                return true;
            }else{
                if(modelId && vcui.detect.isMobile) {
                    var url =  lgkorUI.parseUrl(location.href);
                    var param = url.searchParams.getAll();
                        param.openAR = modelId;
                    goAppUrl(url.origin+url.pathname+'?'+$.param(param));
                    return true;
                } else {
                    return false;
                }
            }
        },
        
        // history back 사용하기
        addHistoryBack:function(cid, callback){

            if(!isMobileDevice) return;

            var uid = '.history-back-'+cid;
            $(window).off('popstate'+uid).on('popstate'+uid, function(){      
                var state = window.history.state;
                if(state && state.data && state.data == uid){
                    if(callback && vcui.isFunction(callback)) callback.call(this);
                    $(window).off('popstate'+uid);
                }
            });

            window.history.replaceState({ data: uid }, null, null);
            window.history.pushState({ data: uid+'-open' }, null, null);

        },

        removeHistoryBack:function(cid){

            if(!isMobileDevice) return;

            var uid = '.history-back-'+cid;
            var state = window.history.state;
            if(state && state.data && state.data == uid+'-open'){
                window.history.back();
            }
            $(window).off('popstate'+uid);
        },

        // BTOCSITE-44203 [UI]시그니처 브랜드관 개편(2차 오픈)-GA
        /**
         * ui 구현된 탭(ui/stickyTab, ui/tab)의 변경에 따른 ga 'page_view' 호출 함수
         * ga 가이드의 digitalData 객체는 페이지 최초 접근에만 대응하므로 ui탭 이동은 'page_view' 호출되지 않는 문제해결 용도
         * 
         * 2뎁스 까지만 지원합니다.
         * history back 에 대한 popstate 만 지원합니다.
         * 
         * @param {string}  text    digitalData.pageInfo.content_depth2~5 의 | 구분자 이후 2뎁스 값
         * @param {object}  options
         * @param {string}      options.key query string 의 parameter key [default: 'tab']
         * @param {string}      options.value query string 의 parameter value
         * @param {function}    options.onBack popstate 의 callback 함수 ( 인자로 복구할 value 값 반환, ui탭 활성화 재설정에 필요 )
         * @param {string}      options.stateKey pushState 에 지정할 state 고유 key ( pushState 호출 시점의 버튼명을 value로 가짐 )
         * 
         * @example 
         *  function clickHandler() {
         *      //?tab=product#product 의 pushState() 에 의해 ga 'page_view' 호출하여 digitalData 재 수집 유도
         *      lgkorUI.addGaPageViewAsQueryStr('제품 정보', { key: 'tab', value: 'product', onBack: callback });
         *  }
         *
         */
        addGaPageViewAsQueryStr : (function () {
            var defaults = {
                key: 'tab',
                value: null,
                onBack: null,
                stateKey: 'go_back'
            };
            var opts = {};

            function _changeDigitalData(value) {
                var str = digitalData.pageInfo.content_depth1 + '|' + value;
                
                digitalData.pageInfo.content_depth2 = str;
                digitalData.pageInfo.content_depth3 = str;
                digitalData.pageInfo.content_depth4 = str;
                digitalData.pageInfo.content_depth5 = str;
            }

            function _getDigitalDataLastValue(depth) {
                var returnValue;

                if (digitalData && digitalData.hasOwnProperty('pageInfo')) {
                    var data = digitalData.pageInfo['content_depth' + depth];
                
                    if (data && data.split('|').length > 1) {
                        returnValue = data.split('|').pop();
                    }
                }
                
                return returnValue;
            }

            function _changeUrl(value, prev) {
                var state = {};
                state[opts.stateKey] = prev; // 이전 메뉴명 저장 해놓아야 hash 있는 상태 back() 시 참조
                
                var searchParams = new URLSearchParams(location.search);
                searchParams.set(opts.key, value);

                if (searchParams.size > 0) history.pushState(state, null, '?' + searchParams.toString());
            }

            function _changeHash(value) {
                location.hash = '#' + value; // history api 이용하면 page_view 실행되므로
            }

            // <!-- hash 변경 이벤트
            function _hashPopStateHandler(e) {
                _removeHashPopStateEvent();

                // hash 있는 상태에서 back() 감지해 digitalData 변경 후 back() 수동 호출
                if (e.state && e.state.hasOwnProperty(opts.stateKey)) {
                    if (!e.state[opts.stateKey]) return;

                    _changeDigitalData(e.state[opts.stateKey]); // digitalData 변경

                    addQueryPopStateEvent(); // ui상태 복구
                    
                    history.back(); // ga page_veiw 호출
                }
            }

            function _addHashPopStateEvent() {
                _removeHashPopStateEvent();
                window.addEventListener('popstate', _hashPopStateHandler);
            }
            
            function _removeHashPopStateEvent() {
                window.removeEventListener('popstate', _hashPopStateHandler);
            }
            // hash 변경 이벤트 -->
            
            // <!-- query 변경 이벤트
            function _queryPopStateHandler(e) {
                _removeQueryPopStateEvent();

                var url = new URL(location).searchParams;
                var queryValue = url.get(opts.key);
                
                // callback
                if (opts.onBack) opts.onBack(queryValue);

                // back() 후 url query 있을 때
                if (queryValue) {
                    // addGaPageViewAsQueryStr() 했을 때와 같은 상황을 만듦
                    _addHashPopStateEvent(); 
                }
            }
                
            function addQueryPopStateEvent() {
                _removeQueryPopStateEvent();
                window.addEventListener('popstate', _queryPopStateHandler);
            }

            function _removeQueryPopStateEvent() {
                window.removeEventListener('popstate', _queryPopStateHandler);
            }
            // query 변경 이벤트 -->

            return function (text, options) {
                var prevMenuText = _getDigitalDataLastValue(2); // 현재 위치 메뉴명 digitalData 에서 참조

                if (!prevMenuText) {
                    throw new Error('Not found depth2 in digitalData.pageInfo');
                }

                opts = Object.assign({}, defaults, options);

                if (text && opts.value) {
                    _changeDigitalData(text); // digitalData 변경
                
                    _changeUrl(opts.value, prevMenuText); // pushState 로 history 변경
                    _changeHash(opts.value); // hash 를 한번 더 쌓아야 digitalData 변경 타이밍 잡을 수 있음
    
                    _addHashPopStateEvent(); // hash 변경 이벤트 등록, back() 대응
                }
            }
        })(),

        //앱 설치 여부 체크

        //iOS, Android 앱 설치 여부 확인
        isAPPInstall:function(iosScheme, androidScheme) {
            var self = this;
            if (vcui.detect.isAndroid || vcui.detect.isIOS) {
                self.heartbeat = setInterval(function () {
                    void 0;
                    if(document.webkitHidden || document.hidden || document.mozHidden){
                        clearTimeout(self.appCheckTimer);
                        clearInterval(self.heartbeat);
                        void 0;
                    }
                }, 2);
            }

            if (vcui.detect.isAndroid) {
                self.appCheckTimer = setTimeout(function() {
                    clearInterval(self.heartbeat);
                    void 0;
                    $(window).trigger("appNotInstall");
                }, 3000);
                location.href = androidScheme;
            } else if (vcui.detect.isIOS) {
                self.appCheckTimer = setTimeout(function() {
                    clearInterval(self.heartbeat);
                    void 0;
                    $(window).trigger("appNotInstall");
                }, 3000);
                location.href = iosScheme;
            }
        },

        /**
         * 기간일 설정
         * @param {String} startTime - 시작일
         * @param {String} endTime - 종료일
         * @param {String} nowTime - 현재시간 ( 서버 타임 넘어올경우, 나머지는 로컬타임 )
         * @returns {Boolean} true  - 행사중
         * @returns {Boolean} false - 행사기간 지남
         * URL 파라미터형식
         * ?dateTest=변경할 시간,행사 시작일,행사 종료일
         * ?dateTest=20200808,20200801,20200807
         * 날짜 형식 : 년월일시분초 ex> 20200820 or 20200820230159
         */
         isShowDate: function(startTime, endTime, nowTime) {
            var self = this;
            var dateTest = self.getParameterByName("dateTest").split(",").filter(Boolean); // 테스트용 dateTest 파라미터 체크
            var debug = self.getParameterByName("debug");

            // 날짜 셋팅
            var setDate = function(time) {
                var limitTime = null;

                if (!time) {
                    limitTime = new Date();
                } else {
                    var regex = /^[0-9]*$/g;
                    if (!regex.test(time)) {
                      throw ("error : 형식 에러");
                    }

                    if (typeof time === 'number') {
                        time = time + '';
                    }

                    if (time.length < 8)  throw ("error : 형식 에러")

                    var year = time.slice(0, 4);
                    var month = time.slice(4, 6);
                    var day = time.slice(6, 8);
                    // 시간, 분 체크 필요시 사용
                    var hours = time.slice(8, 10) || '00';
                    var minutes = time.slice(10, 12) || '00';
                    var second = time.slice(12, 14) || '00';

                    limitTime = new Date(year+'/'+month+'/'+day+' '+hours+':'+minutes+':'+second);
                }

                return limitTime.getTime();
            };

            var printDate = function(time) {
                return new Date(time - new Date().getTimezoneOffset() * 60000).toISOString().replace('T',' ').slice(0,-5)
            };

            try {
                nowTime   = setDate(dateTest.length == 0 ? nowTime   : dateTest[0]);  // 현재시간
                startTime = setDate(dateTest.length <= 1 ? startTime : dateTest[1]);  // 행사 시작일
                endTime   = setDate(dateTest.length <= 1 ? endTime   : dateTest[2]);  // 행사 종료일
            } catch (e) {
                void 0;
                return false;
            }

            if(debug === 'y') {

                void 0;
                void 0;

                void 0;

            }

            return nowTime >= startTime && nowTime < endTime ? true : false;
        },

        /**
         * goUrl 함수 : 링크 처리하는 함수 
         * 파라메타는 HTMLElement 또는 Object 
         * @param { HTMLElement } obj 
         * @param { href : '' , target:'', openMode : '' } obj 
         * 
         */
         goUrl: function(obj) {

            if(!obj || !obj instanceof Object) var obj = {};

            if(obj instanceof HTMLElement) {
                obj = $(obj).data();
            } 

            obj   = $.extend( { href : '',target : '',openMode : '' } , obj );

            if(obj.href) {
                if(isApp()) {
                    // 앱 케이스
                    switch(obj.openMode) {
                        case 'inAppBrowser' :

                            var url = lgkorUI.parseUrl(obj.href),
                                params = $.extend(url.searchParams.getAll(),{'openMode': obj.openMode});
                                obj.href = obj.href.split('?')[0] + '?' + $.param(params)+(url.hash || '');
    
                            if(vcui.detect.isIOS){
                                var jsonString = JSON.stringify({'command':'openInAppBrowser', 'url': obj.href, 'titlebar_show': 'N'});  
                                webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                            } else {
                                android.openNewWebview(obj.href);
                            }
                        break;
    
                        case 'outlink' : 
                            if(vcui.detect.isIOS){
                                var jsonString = JSON.stringify({'command':'openLinkOut', 'url': obj.href});
                                webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                            } else {
                                android.openLinkOut(obj.href);
                            }
                        break;
                        default : 
                            location.href = obj.href;
                        break;
                    }
                } else {
                    // 일반 브라우져
                    if(obj.target === '_blank') {
                        window.open(obj.href);
                    } else {
                        location.href = obj.href;
                    }
                }     
            }
        },
        // BTOCSITE-12458 [앱스플라이어] 이벤트 공통 함수
        afEvent: function(eventName,eventValue){
            if(isApp() && eventName && eventValue) {
                var eventValue = JSON.stringify(eventValue);
                var iframe = document.createElement("IFRAME");
                    iframe.setAttribute("src", "af-event://inappevent?eventName="+eventName+"&eventValue="+eventValue);
                    document.documentElement.appendChild(iframe);
                    iframe.parentNode.removeChild(iframe);
                    iframe = null;
            }
        },
        // BTOCSITE-17070 챗봇 핀코드 생성 호출 개선 건
        getChatPinCode: function(target,popupWidth,popupHeight,intLeft,intTop) {
            var self = this;
            var loginFlag = digitalData.hasOwnProperty("userInfo") && digitalData.userInfo.unifyId ? "Y" : "N";

                if(!self.CHAT_PIN_CODE && loginFlag === 'Y') {
                    lgkorUI.requestAjaxData('/support/getPinCode.lgajax', null, function(result) {
                        var data = result.data;
                        if(data) {
                            var receveResult = data.result;
                            if(receveResult && receveResult.code) {
                                self.CHAT_PIN_CODE = receveResult.code;
                            }
                        }
    
                        var url = lgkorUI.parseUrl(target),
                            params = $.extend(url.searchParams.getAll(),{'channel': isApp() ? "lg_app" : "lg_homepage", 'code' :  self.CHAT_PIN_CODE || ''});
                        target = vcui.uri.addParam(url.origin+url.pathname,params); // BTOCSITE-38600 챗봇 아이콘 변경 및 활성화
                        // el.attr('href',vcui.uri.addParam(url.origin+url.pathname,params));
                        window.open(target, '_blank', 'width=' + popupWidth + ',height=' + popupHeight + ',left=' + intLeft + ',top=' + intTop + ',history=no,resizable=no,status=no,scrollbars=yes,menubar=no');

                    },"GET", "json", true, null, true);
                } else {
                    var url = lgkorUI.parseUrl(target),
                        params = $.extend(url.searchParams.getAll(),{'channel': isApp() ? "lg_app" : "lg_homepage", 'code' :  loginFlag === 'N' ? '' : self.CHAT_PIN_CODE});
                    target = vcui.uri.addParam(url.origin+url.pathname,params); // BTOCSITE-38600 챗봇 아이콘 변경 및 활성화
                    // el.attr('href',vcui.uri.addParam(url.origin+url.pathname,params));
                    window.open(target, '_blank', 'width=' + popupWidth + ',height=' + popupHeight + ',left=' + intLeft + ',top=' + intTop + ',history=no,resizable=no,status=no,scrollbars=yes,menubar=no');
                }
        },
        // BTOCSITE-13955 ThinQ LGE.com 앱간 자동 로그인 연계
        integrateData: {},
        chkThinkQLink: null,
        setThinkQLink: function() {
            var ajaxUrl = '/sso/api/getThinqDeepLink.lgajax';
            // ajaxUrl = '/lg5-common/data-ajax/common/AmSsoInfo.json';
            // ThinkQ 연결하기 링크 변경
            if(lgkorUI.chkThinkQLink) return false;
            lgkorUI.requestAjaxData(ajaxUrl, null, function(result) {
                if( result.status == 'success' ) {
                    $('.item-box-wrap a, .nav-outer-link .thinq').each(function() {
                        if($(this).attr('href').indexOf('//lgthinq.page.link/') !== -1 || $(this).hasClass('thinq')) {
                            $(this).attr('href', result.link).addClass('lgthinq-link');
                        }
                    });
                    lgkorUI.chkThinkQLink = true;
                }
            },"GET", "json", true, null, true);

            $(document).off('click.integrateEvent').on('click.integrateEvent', '.lgthinq-link', function() {
                lgkorUI.requestAjaxData(ajaxUrl, null, function(result) {
                    if( result.status == 'success' ) {
                        window.open(result.link);
                    }
                },"GET", "json", true, null, true);
                return false; // 중복수정
            });
        },
        integrateChkCookie: function(cookie_name) {
            var userId = vcui.Cookie.get(cookie_name);
            var msg = '<br>로그인 계정 : ' + userId;
            if(userId) {
                lgkorUI.confirm(msg, {
                    title: '이전에 로그인했던 정보가 있습니다.<br>로그인 하시겠습니까?<br>로그인 시 자동로그인 처리됩니다.',
                    cancel: function() {
                        $(['sso_id','sso_display_user_id']).each(function(i, name) {
                            vcui.Cookie.set(name,'',{
                                expires : new Date('1999/01/01'),
                                domain : location.host
                            });
                        });
                    },
                    ok: function(){
                        location.href = '/sso/api/integrateLogin?state=' + encodeURIComponent(location.href);
                    }
                });
            }
        },
        integrateLoginEvent: function(){
            if(Object.keys(lgkorUI.integrateData).length>0) return false;
            if(lgkorUI.getParameterByName('src_svc_code') === 'SVC202'
                || lgkorUI.getParameterByName('edec') === 'SVC202') {
                var url = lgkorUI.parseUrl(location.href);
                var params = url.searchParams.getAll();
                var keys;
                if(params['emde'] === undefined)
                    keys = ['ci', 'sso_id', 'thinq_mbrno', 'id_tp_code'];
                else
                    keys = ['reno', 'reqn', 'nogn', 'edeo', 'emde'];
                $(keys).each(function(i, key) {
                    if(params[key] != undefined) lgkorUI.integrateData[key] = params[key];
                });
            }
            vcui.require(['ui/modal'], function () {
                if(lgkorUI.stringToBool(lgkorUI.getParameterByName('integrateIdCancel'))) {
                    lgkorUI.cancelIntegrateId();
                }
                if(lgkorUI.getParameterByName('src_svc_code') === 'SVC202'
                    || lgkorUI.getParameterByName('edec') === 'SVC202') {
                    lgkorUI.checkIntegrateId('/sso/api/checkIntegrateId');
                }
                lgkorUI.integrateChkCookie('sso_display_user_id');
            });
        },
        checkIntegrateEvent: function(){
            var cookie_name = 'idIntegrationChecked';
            if (vcui.Cookie.get(cookie_name) === 'Y') {return false;}
            else{
                vcui.require(['ui/modal'], function () {
                    lgkorUI.checkIntegrateId('/sso/api/checkIntegrateMbrno');
                });
            }
        },
        pageGoPost: function(data) {
            var insdoc = ""; 
            for (var i = 0; i < data.params.length; i++) { 
                insdoc+= "<input type='hidden' name='"+ data.params[i][0] +"' value='"+ data.params[i][1] +"'>"; 
            }
            var goform = $("<form>", { method: "post", action: data.url, target: data.target, html: insdoc }).appendTo("body"); 
            goform.submit(); 
        },
        checkIntegrateId: function(ajaxUrl){
            if(Object.keys(lgkorUI.integrateData).length <= 0 && ajaxUrl === '/sso/api/checkIntegrateId') return false;
            //var ajaxUrl = '/sso/api/checkIntegrateId';
            // ajaxUrl = '/lg5-common/data-ajax/common/checkIntegrateId.json';
            lgkorUI.requestAjaxData(ajaxUrl, lgkorUI.integrateData, function(result) {
                if( result.status != 'success' ) return false;
                var data = result.data;
                var sendata = lgkorUI.integrateData;
                var loginFlag = digitalData.hasOwnProperty('userInfo') && digitalData.userInfo.unifyId ? true:false;
                var msg, opt={};

                switch(data.integrateType) {
                    case 'popup1' : 
                        msg = 'ThinQ에 가입하신 계정과 LGE.com 에<br> 가입하신 계정을 연결하시겠습니까? <br>연결하시면 LG가 제공하는 다양한 서비스를<br> 편리하게 이용하실 수 있습니다.';
                        opt = {
                            cancel: function(){
                                if(loginFlag) {
                                    lgkorUI.cancelIntegrateId()
                                }else {
                                    location.href = '/sso/member/login?state=' + encodeURIComponent(lgkorUI.replaceIntegrateUrl('&integrateIdCancel=true'));
                                }
                            },
                            ok: function(){
                                lgkorUI.pageGoPost({
                                    url: '/sso/api/integrateId?state=' + encodeURIComponent(location.href),
                                    target: '_self',
                                    params: [
                                        ['sso_id', data.ssoId],
                                        ['thinq_mbrno', data.empMbrNo],
                                        ['id_tp_code', data.idTpCode],
                                        ['itgTrgtUserNo', data.itgTrgtUserNo],
                                        ['itgTrgtExceptUserNo', data.itgTrgtExceptUserNo],
                                        ['itgUserType', 'B']
                                    ]
                                });
                            }
                        };
                        lgkorUI.confirm(msg, opt);
                    break;
                    case 'popup2' : 
                        msg = 'ThinQ와 LGE.com에 연결된 계정은<br>' + data.displayUserId + ' 입니다.<br>'
                        + '위 계정으로 연결 하시겠습니까?<br>'
                        + '(현재 로그인을 유지하면 ThinQ에서 조회한 정보와 다를 수 있습니다.)';
                        opt = {
                            cancel: function(){
                                lgkorUI.cancelIntegrateId()
                            },
                            ok: function(){
                                lgkorUI.pageGoPost({
                                    url: '/sso/api/integrateLogin?state=' + encodeURIComponent(location.href),
                                    target: '_self',
                                    params: [
                                        ['sso_id', data.ssoId]
                                    ]
                                })
                            }
                        };
                        lgkorUI.confirm(msg, opt);
                    break;
                    case 'popup3' : 
                        msg = 'LGE.com 로그인 정보와 ThinQ 앱 로그인 정보가 다르기 때문에 멤버십 포인트는 ThinQ 앱에서 보여지는 정보와 다릅니다.';
                        lgkorUI.alert(msg, {ok: function(){
                            location.href = '/my-page';
                        }});
                    break;
                    case 'popup4' : 
                        msg = 'LGE.com 회원이 아닙니다.<br> ThinQ 회원정보로 LGE.com에<br> 가입 하시겠습니까?';
                        opt = {
                            cancel: function(){
                                location.href = '/sso/member/login?state=' + encodeURIComponent(lgkorUI.replaceIntegrateUrl('&integrateIdCancel=true'));
                            },
                            ok: function(){
                                lgkorUI.pageGoPost({
                                    url: '/sso/api/serviceSignup',
                                    target: '_self',
                                    params: [
                                        ['sso_id', data.ssoId],
                                        ['thinq_mbrno', data.empMbrNo],
                                        ['id_tp_code', data.idTpCode]
                                    ]
                                });
                            }
                        };
                        lgkorUI.confirm(msg, opt);
                    break;
                    case 'popup5' : 
                        var txt_mobile = (data.mobileNo.replace(/\s/gi, '') == '')?'':'<br> Mobile : '+ data.mobileNo.replace(/\s/gi, ''), 
                            txt_email = (data.emailAddr.replace(/\s/gi, '') == '')?'':'<br> Email : '+ data.emailAddr.replace(/\s/gi, '');
                        msg = 'ThinQ와 LGE.com이 연결된 계정은'
                        + txt_mobile + txt_email +' 입니다.<br>'
                        + '위 계정으로 로그인 해 주세요';
                        lgkorUI.alert(msg, {ok: function(){
                            location.href = '/sso/member/login?state=/my-page';
                        }});
                    break;
                    case 'popup6' : 
                        msg = '<br>ID : ' + data.displayUserId;
                        opt = {
                            title: 'LGE.com 로그인을 원하시면 보유중인 <br>아래 계정으로 로그인 진행해 주세요.',
                            ok: function(){
                                location.href = '/sso/member/login?state=/my-page';
                            }
                        };
                        lgkorUI.confirm(msg, opt);
                    break;
                    case 'popup7' :
                        msg = '통합 가능한 ThinQ계정이 존재합니다.'
                              + '<br>LGE.COM 에 가입하신 계정과 ThinQ 에 가입하신 계정을 연결하시겠습니까?'
                              + '<br>연결하시면 LGE.com 과 ThinQ에 등록하신 보유제품이 자동으로 연동됩니다.';
                        opt = {
                            cancel: function(){
                            },
                            ok: function(){
                                lgkorUI.pageGoPost({
                                    url: '/sso/api/integrateId?state=' + encodeURIComponent(location.href),
                                    target: '_self',
                                    params: [
                                        ['sso_id', data.ssoId],
                                        ['thinq_mbrno', data.empMbrNo],
                                        ['id_tp_code', data.idTpCode],
                                        ['itgTrgtUserNo', data.itgTrgtUserNo],
                                        ['itgTrgtExceptUserNo', data.itgTrgtExceptUserNo],
                                        ['itgUserType', 'A']
                                    ]
                                });
                            }
                        };
                        lgkorUI.confirm(msg, opt);
                    break;
                    case 'refresh' :
                        var url = lgkorUI.replaceIntegrateUrl();
                        void 0;
                        location.href = url;
                    break;
                    case 'replace' :
                        var url = lgkorUI.replaceIntegrateUrl();
                        void 0;
                        history.replaceState(null, '', url);
                    break;
                }

            },"GET", "json", true, null, true);
        },
        replaceIntegrateUrl: function(addParam) {
            var url = lgkorUI.parseUrl(location.href);
            var params = url.searchParams.getAll();
            delete params.src_svc_code;
            delete params.edec;
            delete params.reno;
            delete params.reqn;
            delete params.nogn;
            delete params.edeo;
            delete params.emde;

            params = Object.keys(params).length > 0 ? '?'+$.param(params) : '';
            if (addParam) {
                params += (params === '') ? '?' + addParam : '&' + addParam;
            }

            return url.pathname + params;
        },
        cancelIntegrateId: function(){
            var ajaxUrl = '/sso/api/integrateIdCancel';
            // ajaxUrl = '/lg5-common/data-ajax/common/integrateIdCancel.json';
            lgkorUI.requestAjaxData(ajaxUrl, null, function(result) {
                if( result.status != 'success' ) return false;
                var data = result.data;
                switch(data.integrateType) {
                    case 'popup3' : 
                        var msg = 'LGE.com 로그인 정보와 ThinQ 앱 로그인 정보가 다르기 때문에 멤버십 포인트는 ThinQ 앱에서 보여지는 정보와 다릅니다.';
                        lgkorUI.alert(msg, {ok: function(){
                            location.href = '/my-page';
                        }});
                    break;
                }
    
            },"GET", "json", true, null, true);
        },
        // S : BTOCSITE-15718
        checkBroadCast: function(url) {
            if($('#sauce_live').size() == 0) {
                var ajaxUrl =  'https://api.sauceflex.com/V1/internal/broadcast/'+url;
                // var ajaxUrl =  'https://api.sauceflex.com/V1/front/broadcast/lge.com/'+url;
                lgkorUI.requestAjaxData(ajaxUrl, null, function(result) {
                    if( result.code != 'SU0000' || result.response.items.length <= 0) return false;
                    var data = result.response.items[0];

                    // BTOCSITE-30343 : 방송ID 잘못 입력된 경우 방송 상태를 보고 실행안함
                    if(data.broadcastStateCode == 'cancel') {
                        sessionStorage.removeItem("broadcastId");
                    } else if(data.outputInfo.length > 0 ) {
                        // BTOCSITE-64568 라이브 플로팅기능 교체의 건
                        $('body').append('<div id="sauce_live" />');
                        vcui.require(['https://player.sauceflex.com/static/js/SauceLiveLib.js'], function () {
                            //lgkorUI.executeBroadCast(data);
                            var sessionBroadcastId = sessionStorage.getItem("broadcastId");
                            var sessionLiveUrl = sessionStorage.getItem("liveUrl");
                            if(!sessionLiveUrl) {
                                sessionLiveUrl = data.shortUrl;
                            }
                            var scriptHtml = "";
                            scriptHtml += '<script>';
                            scriptHtml += '     function sauceflexFloatingModeFullscreen() {';
                            scriptHtml += '         console.log(\" func sauceflexFloatingModeFullscreen in ! \");';
                            scriptHtml += '         location.href = \''+ sessionLiveUrl +'\';';
                            scriptHtml += '     }';
                            scriptHtml += '     function sauceflexFloatingModeExit() {';
                            scriptHtml += '         console.log(\" func sauceflexFloatingModeExit in ! \");';
                            scriptHtml += '         $(\"#sauce_live\").remove();';
                            scriptHtml += '         sessionStorage.removeItem(\"broadcastId\");';
                            scriptHtml += '     }';
                            scriptHtml += '     window.SauceLiveLib.setInit({broadcastId: \''+ sessionBroadcastId +'\'});';
                            scriptHtml += '     window.SauceLiveLib.setFloatingType({type: \'basic\'});';
                            scriptHtml += '     window.SauceLiveLib.load()';
                            scriptHtml += '</script>';
                            $('body').append(scriptHtml);
                        });
                    }
                },"GET", "json", true, null, true);
            }
        },
        executeBroadCast: function(data) {
            // BTOCSITE-21906 pip 수정 start
            var videoW = {
                min: 120,
                max: 150
            }
            var videoH = {
                min: 212,
                max: 265
            }
            var playerInstance = jwplayer("lge-pip-player").setup({
                "file": data.outputInfo[0].path,
                "height": videoH.min,
                "width": videoW.min,
                "autostart": true,
                "floating": true,
                "allowFullscreen": false,
                "mute" : false,
                "controls" : true,
            });

            playerInstance.on('ready', function(ev) {
                document.getElementById(playerInstance.id).style.height = 0;
                document.getElementById(playerInstance.id).style.width = 0;
                
                playerInstance.setFloating("true");

                var originView = {
                    w: playerInstance.getConfig().containerWidth,
                    x:0,
                    clientX:0
                }
                var $player = $('#lge-pip-player .jw-wrapper');
                $player.css({
                    maxWidth: '240px',
                    minWidth: videoW.min,
                    maxHeight: 'none'
                })
                playerInstance.setControls(false);
                if($player.find('.lge-ctrl').size() == 0) {
                    var lgeCtrl = '<div class="lge-ctrl"></div>'
                    $player.append(lgeCtrl);
                    $('#lge-pip-player .lge-ctrl').css('bottom',0)
                    var $media = $player.find('.jw-media');
                    $media.after(
                        '<div class="btn-area">'+
                            '<div class="btn-top">'+
                                '<button class="btn-expansion"><span class="blind">확대</span></button>'+
                                '<button class="btn-close"><span class="blind">닫기</span></button>'+
                            '</div>'+
                            '<button class="btn-play"><span class="blind">재생</span></button>'+
                            '<button class="btn-stop"><span class="blind">정지</span></button>'+                            
                            '<button class="btn-drag"><span class="blind">드래그</span></button>'+
                        '</div>'
                    );
                    $player.children('.jw-controls-backdrop').remove();
                    $media.append(
                        '<div class="dimmed"></div>'
                    )
                    var $btnArea = $player.find('.btn-area');
                    var dim = $media.children('.dimmed');
                    dim.show().css('opacity', 0);
                    $btnArea.find('.btn-play').hide();
                    $btnArea.css({'opacity': 0, visibility: 'hidden'});

                    // dimmed, button show/hide
                    var dimTime;
                    var iconTime;
                    var aniSpeed = 100;
                    var aniTime = 3000;
                    $player.find('.btn-area, .dimmed').on('click', function() {
                        clearTimeout(dimTime);
                        clearTimeout(iconTime);
                        dim.stop().animate({'opacity':0.5}, aniSpeed, function() {
                            dimTime = setTimeout(function() {
                                dim.stop().animate({'opacity':0}, aniSpeed);
                            },aniTime);
                        });
                        $btnArea.css("visibility", '').stop().animate({'opacity':1}, aniSpeed, function() {
                            iconTime = setTimeout(function() {
                                $btnArea.stop().animate({'opacity':0}, aniSpeed, function() {
                                    $btnArea.css("visibility", 'hidden');
                                });
                            },aniTime);
                        });
                    });

                    // 더블 탭
                    var touchtime = 0;
                    dim.on("click", function() {
                        if (touchtime == 0) {
                            touchtime = new Date().getTime();
                        } else {
                            if (((new Date().getTime()) - touchtime) < 200) {
                                if ($player.width() >= videoW.max) {
                                    $player.stop().animate({width: videoW.min}, 300);
                                    originView.w = videoW.min;
                                } else {
                                    var videoMove = {
                                        width: videoW.max,
                                    };

                                    if ($media.offset().top - $(window).scrollTop() < videoH.max - videoH.min) {
                                        videoMove.bottom = parseInt($player.css('bottom')) - (videoH.max - videoH.min) - ($media.offset().top - $(window).scrollTop())
                                    }
                                    if ($media.offset().left < videoW.max - videoW.min) {
                                        videoMove.right = parseInt($player.css('right'))- (videoW.max - videoW.min) - $media.offset().left;
                                    }
                                    $player.stop().animate(videoMove, 300);
                                    originView.w = videoW.max;
                                }
                                dim.stop().show().css('opacity', 0);
                                $btnArea.stop().css({'opacity': 0, visibility: 'hidden'});
                                touchtime = 0;
                            } else {
                                touchtime = new Date().getTime();
                            }
                        }
                    });

                    // 버튼 바인딩
                    void 0
                    $btnArea.on('click','button',function(e) {
                        var $this = $(e.currentTarget);
                        if ($this.hasClass('btn-close')) {
                            // BTOCSITE-26953 : 웹pip 관련
                            sessionStorage.removeItem("broadcastId");
                            playerInstance.remove();
                        }
                        if ($this.hasClass('btn-expansion')) {
                            //location.href = data.shortUrl;
                        	var sessionLiveUrl = sessionStorage.getItem("liveUrl");
                            if(!sessionLiveUrl) {
                                sessionLiveUrl = data.shortUrl;
                            }
                            location.href = sessionLiveUrl;
                        }
                        if ($this.hasClass('btn-play')) {
                            $this.hide();
                            $this.siblings('.btn-stop').show();
                            playerInstance.play();
                        }
                        if ($this.hasClass('btn-stop')) {
                            $this.hide();
                            $this.siblings('.btn-play').show();
                            playerInstance.pause();
                        }
                    });
                    playerInstance.on('complete', function() {
                        $btnArea.find('.btn-play').show();
                        $btnArea.find('.btn-stop').hide();
                    });

                    // 드래그 resize
                    $player.find('.lge-ctrl').on({
                        'touchmove': function(e) {
                            var _w = originView.w;
                            var _x = originView.x - e.originalEvent.touches[0].clientX;
                            originView.clientX = e.originalEvent.touches[0].clientX;
                            var oldH = $player.height();
                            var oldW = $player.width();
                            $player.css({
                                'width': _w - _x,
                            });
                            var newH = $player.height();
                            var newW = $player.width();
                            var moveH = oldH - newH;
                            var moveW = oldW - newW;
                            $player.css('bottom', parseInt($player.css('bottom')) + moveH);
                            $player.css('right', parseInt($player.css('right')) + moveW)

                            if(_w - _x < 100) {
                                $player.addClass('lge-player-width-s')
                            }else {
                                $player.removeClass('lge-player-width-s')
                            }
                        },
                        'touchstart': function(e) {
                            originView.x = e.originalEvent.touches[0].clientX
                        },
                        'touchend': function(e) {
                            originView.w = $player.width();
                            originView.x = originView.clientX;
                        }
                    });
                }
            })
            if(data.highlightList.length> 0 && data.highlightList[0].startTime) {
                playerInstance.on('firstFrame', function() {
                    // console.log(data.highlightList[0].startTime)
                    var t = data.highlightList[0].startTime.split(':').map(function(e) {return Number(e)});
                    var strTime = t[0] * 3600 + t[1] * 60 + t[2];
                    if(!isNaN(strTime)) playerInstance.seek(strTime);
                });
            }
            // BTOCSITE-21906 pip 수정 end
        },
        // E : BTOCSITE-15718
        // S : BTOCSITE-15539
        setBreadcrumb: function(obj) {
            if($(document).find('.wrap > .breadcrumb').size() > 0) {
                var $breadcrumb = $(document).find('.wrap > .breadcrumb').eq(0);
                var _html = 
                '<li {{#if path}}itemprop="itemListElement" itemscope="" itemtype="http://schema.org/ListItem"{{/if}}>'+
                '{{#if path}}'+
                '<a href="{{path}}" itemprop="item" data-link-area="gnb-breadcrumb" data-link-name="{{name}}">'+
                '<span itemprop="name">{{name}}</span>'+
                '<meta itemprop="position" content="{{idx}}">'+
                '</a>'+
                '{{#else}}' + 
                '<span itemprop="name">{{name}}</span>'+
                '{{/if}}'+
                '</li>';
                $breadcrumb.find('li').not(':first-child').remove();
                $.map( obj, function( a, b ) {
                    if(a.path == '' || a.path == undefined) a.path = false;
                    a.idx = Number(b)+1;
                    $breadcrumb.find('ul').append(vcui.template(_html,a));
                });
            }
        },
        // E : BTOCSITE-15539

        // S : BTOCSITE-20351
        /**
         * 챗봇창을 호출한다.
         * 서비스 미지정시 챗봇 인트로 페이지 호출
         * [BTOCSITE-39081] 2.0 챗봇 연계
         * @param {string} service 서비스명. ex) 제품간단조치방법, 구매전문가상담
         */
        goChatbot: function(service) {
            var self = this;
            //var url = 'https://chat.lge.co.kr/kr/index.html';
            var url = 'https://chat.lge.co.kr';
            var parseUrl = lgkorUI.parseUrl(window.location.hostname);
            if( parseUrl.hostname == lgkorUI.DOMAIN_LIST[1] ) {
            	url = 'https://chat-qa.lgechat.co.kr';
            }else if( parseUrl.hostname == lgkorUI.DOMAIN_LIST[2] || parseUrl.hostname == lgkorUI.DOMAIN_LIST[6] || parseUrl.hostname == lgkorUI.DOMAIN_LIST[7] ) {
            	url = 'https://chat-st.lgechat.co.kr';
            }

            //if (!(service === lgkorUI.CHATBOT_SERVICE_LIST[0] || service === lgkorUI.CHATBOT_SERVICE_LIST[1]))
            //  this.goChatbotUrl(url);

            var params = {
                "channel": isApplication ? "lg_app" : "lg_homepage",
                "speech": service
            };
            if (isApplication) params.inflow = 'mobileapp';

            var isLogin = digitalData.hasOwnProperty("userInfo") && digitalData.userInfo.unifyId ? true : false;
            if (!lgkorUI.CHAT_PIN_CODE && isLogin) {
                lgkorUI.requestAjaxData('/support/getPinCode.lgajax', null, function(result) {
                    var data = result.data;
                    if (data) {
                        var receiveResult = data.result;
                        if (receiveResult && receiveResult.code) {
                            lgkorUI.CHAT_PIN_CODE = receiveResult.code;
                        }
                    }

                    params.code = lgkorUI.CHAT_PIN_CODE;

                    url = vcui.uri.addParam(url, params);
                    self.goChatbotUrl(url);

                });
            } else {
                if (isLogin) params.code = lgkorUI.CHAT_PIN_CODE;
                url = vcui.uri.addParam(url, params);
                this.goChatbotUrl(url);
            }

        },
        goChatbotUrl: function(url) {
            if (isApplication) {
                if (isIOS) {
                    var obj = {
                        'command': 'openInAppBrowser',
                        'url': url,
                        'bottombar_show': 'N',
                        'titlebar_show': 'N',
                        'title': '',
                        'callback': ''
                    };

                    var jsonString = JSON.stringify(obj);
                    webkit.messageHandlers.callbackHandler.postMessage(jsonString);
                } else {
                    android.openNewWebview(url);
                }
            } else {
                window.open(url, '_blank', 'width=' + 450 + ',height=' + 760);
            }
        },
        // E : BTOCSITE-20351

        setRecentViewProduct : function(modelId) {
            var data = {modelId : modelId};
            sessionStorage.setItem("recent_view_pdp_info", JSON.stringify(data));
        },

        _appInstallBenefitPopup: function() {
            $(function() {
                var cookie_name ="APP_BANNER_POPUP"; //팝업 쿠키명
                //로그인체크
                var isLogin = digitalData.hasOwnProperty("userInfo") && digitalData.userInfo.unifyId ? true : false;

                //앱 + 로그인 + 메인 홈 여부
                if (isApp() && isLogin && window.location.pathname === '/') {
                    if(vcui.isEmpty(lgkorUI.getCookie(cookie_name))) {
                        //다른 팝업 떠 있는지 체크
                        var isOtherPopupOpen = false;
                        $('article.popup-wrap').each(function(idx, item){
                            if($(item).css("display") == "block") {
                                isOtherPopupOpen = true;
                            }
                        });
                        //떠 있는 팝업이 없을 경우
                        if($('#appbanner-popup').size() === 0 && !isOtherPopupOpen) {
                            lgkorUI.requestAjaxData("/retrieveAppInstallBenefitPopupHtml.lgajax", null, function(result) {
                                var popupHtml = result.data[0].popupHtml;
                                $('body').append(vcui.template(popupHtml));
                                var $popupObj = $("#appbanner-popup");

                                //팝업 하단 닫기버튼 눌렀을때
                                $popupObj.on('click','.pop-footer button',function(e){
                                    e.preventDefault();
                                    var expireDay = $(this).data("expire-day");
                                    if(expireDay != null && expireDay != '' && expireDay != 0){
                                        lgkorUI.setCookie(cookie_name, "Y", false, expireDay);
                                    }
                                    $popupObj.vcModal('hide');
                                });
                                setTimeout(function(e){
                                    $popupObj.vcModal('show');
                                },100);
                            }, "POST", "json");
                        }
                    }
                }
            });
        },

        _appInstallHelloBar : function() {
            $(function() {
                if (vcui.detect.isMobileDevice && !isApp() && navigator.userAgent.toLocaleLowerCase().indexOf("kakaotalk") < 0) {

                    var cookie_name ="APP_HELLO_BAR"
                    //비 노출 페이지 체크
                    var hiddenUrl = ['^/my-page/?','^/shop/?','^/support/?','^/business/?','^/order-to-pay/?'
                                            ,'^/recently-viewed-products?','^/guest-order-and-delivery-status?','^/lg-best-care?','^/company/?'];
                    var isHidden = hiddenUrl.some(function(element) {
                        return location.pathname.match(new RegExp(element,"g"))
                    });

                    if(!isHidden){
                        //쿠키값 체크
                        if(vcui.isEmpty(lgkorUI.getCookie(cookie_name))) {
                            //다른 띠배너 떠 있을 경우 비노출 함
                            var isDisplayBanner = false;
                            $('.hello-bar-wrap').each(function(idx, item){
                                if($(item).is(':visible') == true){
                                    isDisplayBanner = true;
                                }
                            });
                            if(!isDisplayBanner) {
                                //[파일 없을시 동적 추가
                                if($("link[href='/lg5-common/data-ajax/common/manifest.json']").length < 1){
                                    $('head').append('<link rel="manifest" href="/lg5-common/data-ajax/common/manifest.json"/>');
                                }
                                //앱 설치여부 확인
                                var getAppData = new Promise(function(resolve, reject){
                                    if(vcui.detect.isAndroid) {
                                        resolve(navigator.getInstalledRelatedApps());
                                    }else{
                                        resolve("ios check impossible");
                                    }

                                }).then(function(relatedApps) {
                                    //단말에 앱 설치여부 확인
                                    var param = { isInstall : 'N' };

                                    //안드로이드만 앱 설치여부 체크
                                    if(vcui.detect.isAndroid) {
                                        var installApp = relatedApps.find(function(app){ return app.id === "kr.co.lge.android" });
                                        if(installApp != undefined && installApp != ''){
                                            param.isInstall = 'Y';
                                        }
                                    }//ios는 설치된걸로 set
                                    else{
                                        param.isInstall = 'Y';
                                    }
                                    //띠배너 html 생성
                                    lgkorUI.requestAjaxData("/mkt/api/retrieveAppHelloBarHtml.lgajax", param, function(result) {
                                        var helloBarHtml = result.data[0].helloBarHtml;
                                        if (helloBarHtml != null && helloBarHtml != '') {
                                            if(!$('.header').hasClass('helloBar')) {
                                                $('.header').addClass('helloBar');
                                            }
                                            if(!$('.subRenewWrap').hasClass('hellobar-wrap')) {
                                                $('.subRenewWrap').addClass('hellobar-wrap')
                                            }
                                            if($('.header').attr('data-hellobanner') == undefined ){
                                                $('.header').attr('data-hellobanner','helloBanner');
                                            }
                                            //일반gnb와 mobile gnb 다르게 적용
                                            if($(".hello-bar-wrap.hello-app").length > 0){
                                                $(".hello-bar-wrap.hello-app").replaceWith(helloBarHtml);
                                                $(".hello-bar-wrap.hello-app").css('display','block');
                                            }else{
                                                $('header.header').prepend(helloBarHtml);
                                                $(".hello-bar-wrap.hello-app").css('display','block');
                                            }
                                        }
                                        // 띠 배너 닫기
                                        $('.hello-bar-wrap.hello-app .close').on('click', function(){
                                            var expireDay = $(this).data("expire-day");
                                            if(expireDay != '0'){
                                                lgkorUI.setCookie(cookie_name, "Y", false, expireDay);
                                            }
                                            $('.hello-bar-wrap.hello-app').hide();
                                            $('.header').removeClass('helloBar');

                                            if($('.subRenewWrap').hasClass('hellobar-wrap')) {
                                                $('.subRenewWrap').removeClass('hellobar-wrap')
                                            }
                                        });
                                    }, "POST", "json");
                                });
                            }
                        }
                    }
                }
            });
        }
    }

    window.historyBack = lgkorUI._historyBack;

    document.addEventListener('DOMContentLoaded', function () {
        lgkorUI.init();
        // BTOCSITE-20437 장바구니 구매 탭 예외처리
        if (
            $(".KRP0008").length === 0 && 
            $(".officehome").length === 0 && 
            document.location.href.indexOf(document.location.host + '/shop') < 0 && 
            document.location.pathname !== '/add-to-cart/rental-care-solution' &&
            document.location.pathname.indexOf('mobile-app/option') < 0 && 
            // 20221024 BTOCSITE-18987 베스트케어서비스 하단바 예외처리
            document.location.pathname !== '/lg-best-care' &&
            document.location.pathname !== '/lg-best-care/rentalService' &&
            // BTOCSITE-72394 베스트케어 가전 세척 Page 천장형 에어컨 제품 추가 2차 개발 요청 (가전세척 페이지 하단탭바 노출 필요)
            // document.location.pathname !== '/lg-best-care/home-appliance-cleaning' &&
            document.location.pathname !== '/lg-best-care/service-installation-removal' &&
            document.location.href.indexOf(document.location.host + '/bestshop/storemode/') < 0 &&
            document.location.href.indexOf('/subs-package/detail') < 0
        ) {
            // BTOCSITE-20437 앱 기본 하단바 비활성화 처리 후 해당 함수 실행하여 활성화로 선 전환 (페이지 이동 시 앱 하단바 이미 나와고 각 페이지에서 숨길 때 늦게 사라지는 문제 해결 위함)
            $(window).on('DOMContentLoaded_setAppBottomBar',function(){
                var locationHref =  location.href;
                window.isB2B = locationHref.indexOf('.kr/business') > -1 
                || locationHref.indexOf('/my-page/estimateList') > -1
                || locationHref.indexOf('/my-page/estimateDetail') > -1 
                    //B2B > 마이페이지 my-page?inflow=mobileapp(앱), my-page?inflow=web(웹) 일때는 B2C 로 인식
                || ( locationHref.indexOf('b2bYn=Y') > -1 && locationHref.indexOf('.kr/my-page?') == -1 )? true : false;

                //console.log('======================= window.isB2B '+ window.isB2B);
                
                if( !isApp() ){ return; }
                if(window.isB2B){
                    //B2B 일때
                    //APP > B2B > 하단 고정 버튼이 있어 하단바 비활성화 및 플로팅 배너 위치 보정 리스트
                    var barHideList = lgkorUI.B2BBarHideList = [
                        '/business/my-page/estimateRequest',    //견적문의
                        '/business/my-page/estimateConfirm',    //견적문의완료
                        '/my-page/estimateList',                //견적조회
                        '/my-page/estimateDetail',              //견적상세
                        '/business/businessUpdateVerifyPw',     //사업자 필수정보 수정(비밀번호 확인페이지)
                        '/business/requireUpdate',              //사업자 필수정보 수정
                        '/business/additionalUpdate',           //사업자 부가정보 수정
                        '/business/businessCancelVerifyPw',     //사업자 인증해지(비밀번호 확인페이지)
                        '/business/regCancel',                  //사업자 인증해지 
                        '/business/registration',               //사업자 인증 신청
                        '/business/registration-complete'       //사업자 인증 완료
                    ]
                    var isBarHide = false;
                    for(var i=0; i<barHideList.length; i++){
                        if(locationHref.indexOf(barHideList[i]) > -1){
                            isBarHide = true;
                        }
                    }
                    if(isBarHide){
                        lgkorUI.setEnableAppScrollBottomMenu(false);
                        lgkorUI.showAppBottomMenu(false);
                    }else{
                        lgkorUI.setEnableAppScrollBottomMenu(true);
                        lgkorUI.setBottomMenuType(1);
                        lgkorUI.showAppBottomMenu(true);
                    }
                }else{
                    //B2C 일때
                    lgkorUI.setEnableAppScrollBottomMenu(true);
                    lgkorUI.showAppBottomMenu(true);
                }
            }).trigger('DOMContentLoaded_setAppBottomBar');
        }
    });


    global.addEventListener('load', function(){
        vcui.require(['ui/sticky'], function () {
            $('.ui_sticky').vcSticky('update');
            if(isApp()) lgkorUI.setThinkQLink(); // BTOCSITE-13955 ThinQ LGE.com 앱간 자동 로그인 연계
        });

        //[BTOCSITE-56491] 앱 다운로드 유도 배너 개발 요청 (앱다운혜택 팝업)
        lgkorUI._appInstallBenefitPopup();
        //[BTOCSITE-56491] 앱 다운로드 유도 배너 개발 요청 (띠배너)
        lgkorUI._appInstallHelloBar();
    });

    /* BTOCSITE-23171 금성오락실 방문객 OBS 회원가입 유도를 위한 페이지 제작 */
    $(function(){
        var lgflexticket = $('.lgflexticket');
        lgflexticket.parents('body').css('touch-action', 'pan-x');
        lgflexticket.siblings('.mobile-nav-wrap').find('.btn-back').hide();
    })
    /* //BTOCSITE-23171 금성오락실 방문객 OBS 회원가입 유도를 위한 페이지 제작 */

    /* BTOCSITE-48265 사이트맵 여백 수정 */
    $(function(){
        var sction1 = $('.category-depth.level-1 h2 a:contains("사업자몰")').parents('.category-cluster').find('.category-column.upperframe').eq(0).find('.category-column').eq(0);
        var sction2 = $('.category-depth.level-1 h2 a:contains("사업자몰")').parents('.category-cluster').find('.category-column.upperframe').eq(0).find('.category-column').eq(1);

        //mo의 경우 2열을 1열로 추가
        if(location.pathname == "/sitemap" && vcui.detect.isMobile){
            var sction2_sub = $(sction2).find('.category-depth.level-3');
            $(sction1).append(sction2_sub);
            $(sction2).remove();
        }
    })
    /* BTOCSITE-48265 사이트맵 여백 수정 */

})(window, document);